import { Button } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { HeaderTitle } from '../common/components/Atoms/HeaderTitle/HeaderTitle';

export default function Payment() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	useEffect(() => {
		setTimeout(() => {
			navigate(`/dashboard`);
		}, 3000);
	}, []);

	return (
		<div
			style={{
				display: 'flex',
				height: '100vh',
				width: '100vw',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<HeaderTitle size="50px" title={t('headerPaymentSuccessful')} />
				<span>
					{' '}
					U wordt binnen enkele seconden automatich doorverwezen naar het dashboard.{' '}
				</span>
				<span>
					Gebeurt dit niet, klik dan op onderstaande knop om naar het dashboard te gaan.
				</span>
				<Button
					style={{ width: '150px' }}
					variant="contained"
					id="redirectButton"
					onClick={() => {
						navigate(`/dashboard`);
					}}
				>
					Doorverwijzen
				</Button>
			</div>
		</div>
	);
}
