import { useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useTranslation } from 'react-i18next';

import styling from './_styling.module.scss';

interface IProps {
	menuItems: {
		name: string;
		value: string | number;
	}[];
	inputValue: string;
	setSelectedValue: Function;
	selectName?: string;
	label?: string;
	disabled?: boolean;
	readonly?: boolean;
}

export function FormFieldDropDown(props: IProps) {
	const { t } = useTranslation();

	const {
		menuItems,
		inputValue = '',
		setSelectedValue,
		selectName = '',
		label = '',
		disabled,
		readonly,
	} = props;
	const errorState = false;

	const [selected, setSelectedInternal] = useState<string>(inputValue);
	const handleChangeDropDown = (event: SelectChangeEvent) => {
		setSelectedInternal(event.target.value);
		setSelectedValue(event);
	};

	return (
		<div className={styling.root}>
			<FormControl sx={{ m: 1, minWidth: 120, margin: 0 }}>
				<InputLabel id="inputLabelId">{selectName}</InputLabel>
				<Select
					variant="outlined"
					size="small"
					labelId="inputLabelId"
					id={selectName}
					value={selected}
					label={label}
					onChange={handleChangeDropDown}
					disabled={disabled}
					readOnly={readonly}
					renderValue={(label) =>
						errorState ? `⚠️  - ${t(`${label}`)}` : `${t(`${label}`)}`
					}
				>
					{menuItems.map((option) => (
						<MenuItem key={option.name} value={option.value}>
							{t(`${option.name}`)}
						</MenuItem>
					))}
					;
				</Select>
			</FormControl>
		</div>
	);
}
