import { useTranslation } from 'react-i18next';
import styling from './_styling.module.scss';

interface IProps {
	title: string;
	text: string;
	textOrientationBelow?: boolean;
	link?: boolean;
	email?: boolean;
}

export default function InfoField({
	title,
	text,
	textOrientationBelow = false,
	link = false,
	email = false,
}: IProps) {
	const getLink = (): string => {
		if (!text) {
			return '';
		} else if (text.startsWith('http')) {
			return text;
		} else {
			return `https://${text}`;
		}
	};

	return (
		<div className={`${styling.root} ${textOrientationBelow && styling.textBelow}`}>
			<h1 className={styling.title}>{title}:</h1>
			{link && (
				<span>
					<a target="_blank" href={getLink()}>
						{text}
					</a>
				</span>
			)}
			{email && (
				<span>
					<a href={`mailto:${text}`}>{text}</a>
				</span>
			)}
			{!link && !email && <span>{text}</span>}
		</div>
	);
}
