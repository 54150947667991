import { useContext, useState } from 'react';
import { AxiosContext } from '../contexts/AxiosContext';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import IAccreditation from '../../types/Accreditation/Accreditation';
import { IPendingAccreditationsOverview } from '../../types/Accreditation/PendingAccreditationsOverview';
import IAccreditationEdition from '../../types/Accreditation/AccreditationEdition';

export const useAccreditations = () => {
	const axiosContext = useContext(AxiosContext);
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [loadingGet, setLoadingGet] = useState(false);
	const [loadingUpdate, setLoadingUpdate] = useState(false);

	const getAccreditationsAll = () => {
		setLoadingGet(true);
		return axiosContext
			.instance!.get(`/accreditations`)
			.then((response) => response.data as IAccreditation[])
			.finally(() => setLoadingGet(false));
	};

	const getAccreditationsOpen = () => {
		setLoadingGet(true);

		return axiosContext
			.instance!.get(`/Accreditations/open`)
			.then((response) => response.data as IPendingAccreditationsOverview[])
			.finally(() => setLoadingGet(false));
	};

	const getAccreditationsPending = () => {
		setLoadingGet(true);

		return axiosContext
			.instance!.get(`/Accreditations/pending`)
			.then((response) => response.data as IPendingAccreditationsOverview[])
			.finally(() => setLoadingGet(false));
	};

	const getAccreditationsArchive = () => {
		setLoadingGet(true);

		return axiosContext
			.instance!.get(`/Accreditations/archive`)
			.then((response) => response.data as IPendingAccreditationsOverview[])
			.finally(() => setLoadingGet(false));
	};

	const getAccreditationById = (id: string) => {
		setLoadingGet(true);

		return axiosContext
			.instance!.get(`/Accreditations/${id}`)
			.then((response) => response.data as IAccreditation)
			.finally(() => setLoadingGet(false));
	};

	function updateAccreditation(accreditation: IAccreditation): Promise<IAccreditation> {
		setLoadingUpdate(true);

		return axiosContext
			.instance!.put(`/accreditations`, accreditation)
			.then((response) => {
				enqueueSnackbar(t(`textSuccess_UpdateAccreditation`), { variant: 'success' });
				return response.data as IAccreditation;
			})
			.catch(() => {
				enqueueSnackbar(t(`textFailed_UpdateAccreditation`), { variant: 'error' });
				return Promise.reject();
			})
			.finally(() => setLoadingUpdate(false));
	}

	function deleteAccreditationById(id: string) {
		setLoadingUpdate(true);

		return axiosContext
			.instance!.delete(`/Accreditations/${id}`)
			.then(() => {
				enqueueSnackbar(t(`textSuccess_DeleteAccreditation`), { variant: 'info' });
			})
			.catch(() => {
				enqueueSnackbar(t(`textFailed_DeleteAccreditation`), { variant: 'error' });
			})
			.finally(() => setLoadingGet(false));
	}

	//Special functions
	function updateAccreditationStatus(id: string, status: string) {
		setLoadingUpdate(true);

		return axiosContext
			.instance!.put(`/Accreditations/${id}/status/${status}`)
			.then(() => {
				enqueueSnackbar(t(`textSuccess_UpdateStatusAccreditation`), { variant: 'success' });
			})
			.catch(() => {
				enqueueSnackbar(t(`textFailed_UpdateStatusAccreditation`), { variant: 'error' });
			})
			.finally(() => setLoadingUpdate(false));
	}

	function approveAccreditation(id: string, points: number) {
		setLoadingUpdate(true);

		return axiosContext
			.instance!.put(`/Accreditations/${id}/approve`, { points })
			.then(() => {
				enqueueSnackbar(t(`textSuccess_UpdateStatusAccreditation`), { variant: 'success' });
			})
			.catch(() => {
				enqueueSnackbar(t(`textFailed_UpdateStatusAccreditation`), { variant: 'error' });
			})
			.finally(() => setLoadingUpdate(false));
	}

	function addEditionToAccreditation(id: string, edition: IAccreditationEdition) {
		setLoadingUpdate(true);

		return axiosContext
			.instance!.put(`/Accreditations/${id}/edition`, edition)
			.then(() => {
				enqueueSnackbar(t(`textSuccess_AddEdition`), {
					variant: 'success',
				});
			})
			.catch(() => {
				enqueueSnackbar(t(`textFailed_AddEdition`), {
					variant: 'error',
				});
			})
			.finally(() => setLoadingUpdate(false));
	}

	function deleteEditionFromAccreditation(id: string, editionId: string) {
		setLoadingUpdate(true);

		return axiosContext
			.instance!.delete(`/Accreditations/${id}/edition/${editionId}`)
			.then(() => {
				enqueueSnackbar(t(`textSuccess_DeleteEdition`), {
					variant: 'success',
				});
			})
			.catch(() => {
				enqueueSnackbar(t(`textFailed_DeleteEdition`), {
					variant: 'error',
				});
			})
			.finally(() => setLoadingUpdate(false));
	}

	return {
		getAccreditationsAll,
		getAccreditationsOpen,
		getAccreditationsPending,
		getAccreditationsArchive,
		getAccreditationById,
		updateAccreditation,
		deleteAccreditationById,
		updateAccreditationStatus,
		addEditionToAccreditation,
		deleteEditionFromAccreditation,
		approveAccreditation,
		loadingGet,
		loadingUpdate,
	};
};
