import {
	Icon,
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	FormControl,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styling from './_styling.module.scss';

interface IProps {
	openDialog: boolean;
	handleClose: Function;
	handleConfirm: Function;
}

export function ChangeEmailPopup({ openDialog = false, handleClose, handleConfirm }: IProps) {
	const { t } = useTranslation();
	const [email, setEmail] = useState<string>('');

	//hooks
	useEffect(() => {
		if (openDialog) {
			setEmail('');
		}
	}, [openDialog]);

	//functions
	const handleChange = (event: any) => {
		const newValue = event.target.value;
		setEmail(newValue);
	};

	return (
		<Dialog open={openDialog} scroll="paper">
			<div className={styling.HeaderContainer}>
				<h1 className={styling.HeaderTitle}>{t('ChangeEmailPopup')}</h1>
			</div>
			<DialogContent dividers={true}>
				<p>Hieronder kunt u uw e-mailadres wijzigen waarmee u inlogt op uw account.</p>
				<FormControl component="fieldset" variant="standard" style={{ width: '100%' }}>
					<div className={styling.ContentContainer}>
						<TextField
							style={{ width: '100%', marginTop: '16px' }}
							className="email"
							variant="outlined"
							label="E-mailadres"
							value={email}
							placeholder="E-mailadres"
							onChange={handleChange}
						/>
					</div>
				</FormControl>
			</DialogContent>
			<DialogActions style={{ padding: '16px 24px' }}>
				<div className={styling.ActionsContainer}>
					<Button
						variant="contained"
						size="small"
						onClick={() => {
							handleConfirm(email);
						}}
						endIcon={<Icon>send</Icon>}
					>
						Opslaan
					</Button>
					<Button
						color="error"
						variant="contained"
						size="small"
						onClick={() => {
							handleClose();
						}}
						endIcon={<Icon>cancel</Icon>}
					>
						Annuleren
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
}
