import { Icon, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import styling from './_styling.module.scss';

interface IProps {
	openDialog: boolean;
	headerTitle: string;
	bodyText: string;
	handleConfirm: Function;
	handleClose: Function;
}

export function ConfirmationPopup(props: IProps) {
	const { openDialog = false, headerTitle, bodyText, handleConfirm, handleClose } = props;

	return (
		<Dialog open={openDialog} scroll="paper">
			<div className={styling.HeaderContainer}>
				<h1 className={styling.HeaderTitle}>{headerTitle}</h1>
			</div>
			<DialogContent dividers={true}>
				<div className={styling.ContentContainer}>{bodyText}</div>
			</DialogContent>
			<DialogActions style={{ padding: '16px 24px' }}>
				<div className={styling.ActionsContainer}>
					<Button
						variant="contained"
						size="small"
						onClick={() => {
							handleConfirm();
						}}
						endIcon={<Icon>send</Icon>}
					>
						Doorgaan
					</Button>
					<Button
						variant="contained"
						size="small"
						onClick={() => {
							handleClose();
						}}
						endIcon={<Icon>cancel</Icon>}
					>
						Annuleren
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
}
