import { useContext, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { AxiosContext } from '../contexts/AxiosContext';
import IUserDetails from '../../types/User/UserDetails';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import IUser from '../../types/User/User';
import ISupervisor from '../../types/User/Supervisor';

export const useUsers = () => {
	const axiosContext = useContext(AxiosContext);
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState(false);

	const getUsersAll = () => {
		setLoading(true);
		return axiosContext
			.instance!.get(`/users/`)
			.then((response) => response.data as IUser[])
			.finally(() => setLoading(false));
	};

	const getSupervisors = () => {
		setLoading(true);
		return axiosContext
			.instance!.get(`/users/supervisors`)
			.then((response) => response.data as ISupervisor[])
			.finally(() => setLoading(false));
	};

	const getUserMe = () => {
		setLoading(true);

		return axiosContext
			.instance!.get(`/users/me`)
			.then((response) => response.data as IUserDetails)
			.finally(() => setLoading(false));
	};

	const getUserById = (id: string) => {
		setLoading(true);

		return axiosContext
			.instance!.get(`/users/${id}`)
			.then((response) => response.data as IUserDetails)
			.finally(() => setLoading(false));
	};

	function getUserExport() {
		return axiosContext
			.instance!.get(`/users/export/`, { responseType: 'blob' })
			.then((response) => response.data);
	}

	const updateUser = useMutation({
		mutationFn: (user: IUserDetails) =>
			axiosContext.instance!.put(`/users`, user).then((response) => response.data),
		onSuccess: () => enqueueSnackbar(t(`textSuccess_UpdateUser`), { variant: 'success' }),
		onError: () => enqueueSnackbar(t(`textFailed_UpdateUser`), { variant: 'error' }),
	});

	const getUserRoles = (id: string) => {
		return axiosContext
			.instance!.get(`/users/${id}/roles`)
			.then((response) => response.data as string[]);
	};

	const isUserProfileComplete = () => {
		return axiosContext.instance!.get(`users/me/complete`);
	};

	const addUserToRole = (id: string, role: string) => {
		return axiosContext.instance!.post(`/users/${id}/AddToRole/${role}`);
	};

	const removeUserFromRole = (id: string, role: string) => {
		return axiosContext.instance!.post(`/users/${id}/RemoveFromRole/${role}`);
	};

	function updateAccountNumber(accountNumber: string, checkedDirectDebit: boolean) {
		return axiosContext
			.instance!.post(`/users/me/accountNumber`, { accountNumber, checkedDirectDebit })
			.then((response) => {
				enqueueSnackbar(t(`textSuccess_AccountNumberUpdate`), { variant: 'success' });
				return response.data;
			})
			.catch(() => {
				enqueueSnackbar(t(`textFailed_AccountNumberUpdate`), { variant: 'error' });
			});
	}

	function updateEmail(email: string) {
		return axiosContext
			.instance!.put(`/users/email`, { email })
			.then(() => {
				enqueueSnackbar(t(`textSuccess_EmailUpdate`), { variant: 'success' });
			})
			.catch(() => {
				enqueueSnackbar(t(`textFailed_EmailUpdate`), { variant: 'error' });
			});
	}

	return {
		getUsersAll,
		getSupervisors,
		getUserById,
		getUserMe,
		getUserExport,
		updateUser,
		updateEmail,
		isUserProfileComplete,
		getUserRoles,
		addUserToRole,
		removeUserFromRole,
		updateAccountNumber,
		loading,
	};
};
