import { Link } from 'react-router-dom';
import { Icon } from '@mui/material';

import useAuth from '../../../hooks/useAuth';

import styling from './_styling.module.scss';

interface IProps {
	routeItem: {
		icon: string;
		name: string;
		route: string;
		requiredRoles?: string[];
		userType?: string[];
	};
}

export function SidebarItem({ routeItem }: IProps) {
	const { user, isInRole } = useAuth();

	function checkVisibilityByRole(): boolean {
		let returnValue: boolean = false;
		if (!routeItem.requiredRoles || routeItem.requiredRoles.length == 0) {
			returnValue = true;
		} else {
			routeItem.requiredRoles.forEach((role) => {
				if (isInRole(role)) {
					returnValue = true;
				}
			});
		}
		return returnValue;
	}

	function checkVisibilityByUserType(): boolean {
		let returnValue: boolean = false;
		if (!routeItem.userType || routeItem.userType.length == 0) {
			returnValue = true;
		} else {
			routeItem.userType.forEach((userType) => {
				if (!!user) {
					if (userType == 'accreditation' && user.accreditationsEnabled) {
						returnValue = true;
					}
					if (userType == 'registration' && user.registrationsEnabled) {
						returnValue = true;
					}
				}
			});
		}
		return returnValue;
	}

	let visible: boolean = checkVisibilityByRole() && checkVisibilityByUserType();
	return (
		<>
			{visible ? (
				<div className={styling.root}>
					<Link to={routeItem.route}>
						<div className={styling.wrapper}>
							<Icon className={styling.icon}>{routeItem.icon}</Icon>
							<span className={styling.content}>{routeItem.name}</span>
						</div>
					</Link>
				</div>
			) : null}
		</>
	);
}
