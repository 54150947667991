import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

interface IProps {
	children: JSX.Element;
	requiredRoles?: string[];
}

export function RequireAuth({ children, requiredRoles = [] }: IProps): JSX.Element {
	let { user, isInRole } = useAuth();
	let location = useLocation();

	function hasPermission(): boolean {
		let returnValue: boolean = false;
		if (requiredRoles.length == 0) {
			returnValue = true;
		} else {
			requiredRoles.forEach((role) => {
				if (isInRole(role)) {
					returnValue = true;
				}
			});
		}
		return returnValue;
	}

	if (!user) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	if (!hasPermission) {
		//For now the login page, will be error: "No permission"
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return children;
}
