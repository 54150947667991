import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
	config: {
		connectionString: process.env.REACT_APP_APPI_URL,
		extensions: [reactPlugin],
		extensionConfig: {},
		enableAutoRouteTracking: true,
		disableAjaxTracking: false,
		autoTrackPageVisitTime: true,
		enableCorsCorrelation: true,
		enableRequestHeaderTracking: true,
		enableResponseHeaderTracking: true,
	},
});
appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
	env.tags = env.tags || [];
	env.tags['ai.cloud.role'] = 'testTag';
});

export { reactPlugin, appInsights };
