import { useEffect, useState } from 'react';
import useAuth from '../../common/hooks/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, CircularProgress } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export function ForgotPassword() {
	const { forgotPasswordByEmail, error, loading } = useAuth();
	const initialValues: any = {
		username: '',
	};
	const validationSchema = Yup.object().shape({
		username: Yup.string().required('Dit veld is verplicht'),
	});

	const handleForgotPassword = (formValue: any) => {
		forgotPasswordByEmail(formValue.username);
	};

	return (
		<div className="loginFormBody">
			<div className="loginForm">
				<div className="loginFormContainer">
					<img
						alt="logo"
						className="loginImage"
						src={require('../../assets/images/logo.png')}
					/>
					<p className="loginFormText">
						Vul uw e-mailadres is. Als er een account is met dit e-mailadres dan
						ontvangt u een mail om uw wachtwoord te resetten.
					</p>
					{error && (
						<Alert severity="warning">
							Aanvraag mislukt, controleer gegevens en probeer het opnieuw.
						</Alert>
					)}
					<div className="card card-container">
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={handleForgotPassword}
						>
							<Form>
								{loading ? (
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
										}}
									>
										<CircularProgress
											style={{ width: '60px', height: '60px' }}
											color="inherit"
										/>
									</div>
								) : (
									<div className="form-group">
										<Field
											name="username"
											type="text"
											placeholder="E-mailadres"
											className="loginFormField"
										/>
										<ErrorMessage
											name="username"
											component="div"
											className="alert alert-danger"
										/>
									</div>
								)}
								<button
									type="submit"
									className="loginFormButton"
									disabled={loading}
								>
									Verstuur
								</button>
							</Form>
						</Formik>
					</div>
				</div>
			</div>
		</div>
	);
}
export default ForgotPassword;
