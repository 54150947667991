import { TextField } from '@mui/material';
import { useState } from 'react';

import IQuestion from '../../../../types/Question';

import styling from './_styling.module.scss';

interface IProps {
	disabled?: boolean;
	question: IQuestion;
	value: string;
	onValueChange: Function;
}

export function QuestionFieldText({ question, value, disabled, onValueChange }: IProps) {
	const [fieldValue, setFieldValue] = useState<string>(value);

	const handleChange = (event: any) => {
		const newValue = event.target.value;

		setFieldValue(newValue);
		onValueChange(newValue);
	};

	return (
		<div className={styling.Root}>
			<span
				className={`${styling.Question} ${
					!isNaN(+question.order) ? styling.MainQuestion : ''
				}`}
			>
				{!isNaN(+question.order) && `${question.order}  `}
				{question.text}
			</span>
			<TextField
				variant="outlined"
				size="small"
				label=""
				disabled={disabled ?? false}
				value={fieldValue}
				placeholder=""
				onChange={handleChange}
			/>
		</div>
	);
}
