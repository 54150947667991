import { useContext, useState } from 'react';
import { AxiosContext } from '../contexts/AxiosContext';

import ICalendarItem from '../../types/Calendar/CalendarItem';
import ICalendar from '../../types/Calendar/Calendar';

export const useCalendar = () => {
	const axiosContext = useContext(AxiosContext);
	const [loading, setLoading] = useState(false);
	const [loadingGet, setLoadingGet] = useState(false);

	function getCalendar(pageNumber: number = 0, pageSize: number = 10) {
		var requestUrl: string = `/Calendar?pageNumber=${pageNumber}&pageSize=${pageSize}`;

		setLoadingGet(true);

		return axiosContext
			.instance!.get(requestUrl)
			.then((response) => response.data as ICalendar)
			.finally(() => setLoadingGet(false));
	}

	function getItem(id: string) {
		setLoading(true);
		return axiosContext.instance
			?.get(`/Calendar/${id}`)
			.then((response) => response.data as ICalendarItem)

			.catch(() => {})
			.finally(() => setLoading(false));
	}

	return {
		getCalendar,
		getItem,
		loading,
		loadingGet,
	};
};
