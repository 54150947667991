import { CircularProgress, Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { HeaderTitle } from '../../common/components/Atoms/HeaderTitle/HeaderTitle';
import { DataTable } from '../../common/components/Organisms/DataTable/DataTable';
import { DataTableTest } from '../../common/components/Organisms/DataTable/DataTableTest';
import { DetailCard } from '../../common/components/Organisms/DetailCard/DetailCard';
import { useUsers } from '../../common/hooks/useUsers';
import ITableColumn from '../../types/TableColumn';

export function UsersInformation() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { getUsersAll, loading: usersLoading, getUserExport } = useUsers();
	const [tableUsers, setTableUsers] = useState<any>();

	useEffect(() => {
		getUsersAll().then((data) => {
			let tempList: any = [];
			data.forEach((user) => {
				tempList.push({
					id: user.id,
					membershipNumber: user.membershipNumber,
					email: user.email,
					name: [user.firstName, user.middleName, user.lastName]
						.filter(Boolean)
						.join(' '),
				});
			});
			setTableUsers(tempList);
		});
	}, []);

	const columns: ITableColumn[] = [
		{ label: 'membershipNumber', type: 'text' },
		{ label: 'name', type: 'text' },
		{ label: 'email', type: 'text' },
	];

	function handleFunction(activeFunctionCalled: string, rowId: string) {
		if (activeFunctionCalled === 'viewDetails') {
			navigate(`/user-profile/${rowId}`);
		}
	}

	function exportUsers() {
		getUserExport().then((blob) => {
			const url = window.URL.createObjectURL(new Blob([blob]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `user-export.csv`);
			document.body.appendChild(link);
			link.click();
			link.parentNode?.removeChild(link);
		});
	}

	return (
		<>
			{usersLoading ? (
				<Box sx={{ display: 'flex' }}>
					<CircularProgress color="inherit" />
				</Box>
			) : (
				<>
					<HeaderTitle size="50px" title="Gebruikers" />
					<DetailCard headerTitle="">
						<Button variant="contained" onClick={exportUsers}>
							Download overzicht
						</Button>
					</DetailCard>
					<DetailCard headerTitle="">
						{/* <DataTableTest
							columns={columns}
							tableData={tableUsers}
							functions={[{ icon: 'visibility', functionName: 'viewDetails' }]}
							activeFunction={handleFunction}
						></DataTableTest> */}
						<DataTable
							navigateTo={'user-profile'}
							columns={columns}
							tableData={tableUsers}
						></DataTable>
					</DetailCard>
				</>
			)}
		</>
	);
}
