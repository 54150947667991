import { ReactNode } from 'react';

import styling from './_styling.module.scss';

interface IProps {
	children: ReactNode;
}

export function Frame({ children }: IProps) {
	return <div className={styling.root}>{children}</div>;
}
