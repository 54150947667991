import './App.scss';
import { useEffect } from 'react';
import EventBus from './common/EventBus';
import useAuth from './common/hooks/useAuth';
import { AppRoutes } from './pages/_routes';
import { PageLayout } from './common/components/templates/PageLayout';
import { useLocation } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { I18nextProvider } from 'react-i18next';
import i18n from './common/translations/i18n';
import { CssBaseline } from '@mui/material';

function App() {
	const location = useLocation();
	const hidePageLayout =
		location.pathname === '/agenda' ||
		location.pathname === '/login' ||
		location.pathname === '/email-confirm' ||
		location.pathname === '/forgot-password' ||
		location.pathname === '/reset-password' ||
		location.pathname === '/payment' ||
		location.pathname === '/register' ||
		location.pathname === '/trainings' ||
		location.pathname === '/user-register';
	const { logout } = useAuth();

	useEffect(() => {
		EventBus.on('logout', logOut);

		return () => {
			EventBus.remove('logout', logOut);
		};
	}, []);

	const logOut = () => {
		logout();
	};

	return (
		<I18nextProvider i18n={i18n}>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<CssBaseline />
				{hidePageLayout ? (
					<AppRoutes />
				) : (
					<PageLayout>
						<AppRoutes />
					</PageLayout>
				)}
			</LocalizationProvider>
		</I18nextProvider>
	);
}

export default App;
