import axios, { AxiosInstance } from 'axios';
import { createContext, ReactNode, useRef } from 'react';

interface IAxiosContext {
	instance: AxiosInstance | undefined;
}

const initialContext: IAxiosContext = {
	instance: undefined,
};

interface AxiosInterceptorProps {
	children: ReactNode;
}

const AxiosContext = createContext(initialContext);

export const AxiosInstanceProvider = ({ children }: AxiosInterceptorProps) => {
	const instance = useRef(axios.create({ baseURL: `${process.env.REACT_APP_API_URL}` }));

	const jwtInterceptor = (config: any) => {
		var token = localStorage.getItem('token');

		if (!!token) {
			config.headers = {
				Authorization: `Bearer ${token}`,
			};
		}
		return config;
	};

	instance.current.interceptors.request.use(jwtInterceptor);

	return (
		<AxiosContext.Provider value={{ instance: instance.current }}>
			{children}
		</AxiosContext.Provider>
	);
};

export { AxiosContext };
