import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import IUploadReponse from '../../types/Attachments/UploadReponse';
import { AxiosContext } from '../contexts/AxiosContext';

export const useAttachments = () => {
	const axiosContext = useContext(AxiosContext);
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	function getAttachmentById(id: string) {
		return axiosContext
			.instance!.get(`/attachments/download/${id}`, { responseType: 'blob' })
			.then((response) => response.data);
	}

	function uploadAttachment(attachment: FormData) {
		return axiosContext
			.instance!.post(`/attachments/upload`, attachment)
			.then((response) => response.data as IUploadReponse);
	}

	function deleteAttachment(id: string) {
		return axiosContext
			.instance!.delete(`/attachments/delete/${id}`)

			.then((response) => {
				enqueueSnackbar(t('textSuccess_DeleteAttachment'), { variant: 'success' });
				return response.data as IUploadReponse;
			})
			.catch(() => {
				enqueueSnackbar(t(`textFailed_DeleteAttachment`), { variant: 'error' });
			});
	}

	return { getAttachmentById, uploadAttachment, deleteAttachment };
};
