import {
	FormControl,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Icon,
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
} from '@mui/material';
import IBAN from 'iban';
import { useEffect, useState } from 'react';

import styling from './_styling.module.scss';

interface IProps {
	openDialog: boolean;
	showIban: boolean;
	headerTitle: string;
	registrationStatus: string | undefined;
	registrationType: string | undefined;

	handleClose: Function;
	handleConfirm: Function;
}

export function ConfirmRegistrationPopup(props: IProps) {
	const {
		openDialog = false,
		headerTitle,
		handleClose,
		handleConfirm,
		showIban = false,
		registrationStatus,
		registrationType,
	} = props;

	const [checkedStatement, setCheckedStatement] = useState<boolean>(false);
	const [checkedAudit, setCheckedAudit] = useState<boolean>(false);
	const [checkedMeasures, setCheckedMeasures] = useState<boolean>(false);
	const [checkedDirectDebit, setCheckedDirectDebit] = useState<boolean>(false);
	const [IBANValue, setIBAN] = useState<string>();

	const [completeAgreement, setCompleteAgreement] = useState<boolean>(false);

	useEffect(() => {
		CheckCompletion();
	}, [checkedStatement, checkedAudit, checkedMeasures, IBANValue, checkedDirectDebit]);
	const CheckCompletion = () => {
		setCompleteAgreement(checkedStatement && checkedAudit && checkedMeasures && checkIban());
	};

	function checkIban() {
		if (checkedDirectDebit) return !!IBANValue && IBAN.isValid(IBANValue);
		else return true;
	}

	const handleChangeStatement = () => {
		setCheckedStatement((originalValue) => {
			const returnValue = !originalValue;
			return returnValue;
		});
	};

	const handleChangeAudit = () => {
		setCheckedAudit((originalValue) => {
			const returnValue = !originalValue;
			return returnValue;
		});
	};

	const handleChangeMeasures = () => {
		setCheckedMeasures((originalValue) => {
			const returnValue = !originalValue;
			return returnValue;
		});
	};

	const handleChangeIBAN = (event: any) => {
		setIBAN(event.target.value.replace(/ /g, '').toUpperCase());
	};

	const handleChangeDirectDebit = () => {
		setCheckedDirectDebit((originalValue) => {
			const returnValue = !originalValue;
			return returnValue;
		});
	};

	return (
		<Dialog open={openDialog} scroll="paper">
			<div className={styling.HeaderContainer}>
				<h1 className={styling.HeaderTitle}>{headerTitle}</h1>
			</div>
			<DialogContent dividers={true}>
				<FormControl component="fieldset" variant="standard">
					<div className={styling.ContentContainer}>
						<div>
							<h1
								style={{
									color: '#179e97',
									margin: '0',
									lineHeight: '1.3em',
									fontWeight: 'bold',
									fontSize: '20px',
								}}
							>
								Akkoordverklaring:
							</h1>
							<p>
								Met een registratie in het Register MBT laat aanvrager zien te
								beschikken over de MBT kennis en competenties passend bij de
								aangevraagde registratie. Daarnaast verklaart aanvrager hiermee zich
								in te zullen zetten voor het bijhouden van dit kennis- en
								competentieniveau zolang als hij/zij ingeschreven staat. Wet- en
								regelgeving evenals de eigen beroepscode blijven te allen tijde
								leidend.
							</p>
							<p>
								Het Register draagt geen verantwoordelijkheid voor de juistheid van
								de aangeleverde gegevens. Deze ligt bij de aanvrager zelf.
							</p>
							<p>Hierbij verklaar ik dat:</p>
							<div>
								<FormGroup>
									<FormControlLabel
										key={'statement'}
										value={checkedStatement}
										control={
											<Checkbox
												checked={checkedStatement}
												onChange={() => handleChangeStatement()}
											/>
										}
										label={'ik al mijn gegevens naar waarheid heb ingevuld;'}
									/>
								</FormGroup>
								<FormGroup>
									<FormControlLabel
										key={'audit'}
										value={checkedAudit}
										control={
											<Checkbox
												checked={checkedAudit}
												onChange={() => handleChangeAudit()}
											/>
										}
										label={'ik akkoord ga met bovenstaande verklaring;'}
									/>
								</FormGroup>
								<FormGroup>
									<FormControlLabel
										key={'measures'}
										value={checkedMeasures}
										control={
											<Checkbox
												checked={checkedMeasures}
												onChange={() => handleChangeMeasures()}
											/>
										}
										label={
											'mij geen beperkende en/of tuchtrechterlijke maatregelen zijn opgelegd.'
										}
									/>
								</FormGroup>
							</div>
							{registrationType !== 'Supervisor' &&
								registrationStatus != 'ExtraInfoNeeded' &&
								registrationStatus != 'SupervisorState' && (
									<div>
										<h1
											style={{
												color: '#179e97',
												margin: '0',
												lineHeight: '1.3em',
												fontWeight: 'bold',
												fontSize: '20px',
												paddingTop: '8px',
											}}
										>
											Kosten:
										</h1>
										<span>
											Bij registratie worden de volgende kosten in rekening
											gebracht (kijk op onze website voor de actuele
											tarieven):
											<ul>
												<li style={{ listStyle: 'disc' }}>
													Inschrijfkosten (eenmalig indien nog geen
													eerdere registratie is aangevraagd)
												</li>
												<li style={{ listStyle: 'disc' }}>
													Registratiebijdrage (jaarlijks)
												</li>
												<li style={{ listStyle: 'disc' }}>
													Kosten onafhankelijke toets (bij aanvraag
													registratie op specialistisch niveau)
												</li>
											</ul>
											Eenmalige kosten dienen direct te worden voldaan via
											iDEAL. Voor de jaarlijkse registratiebijdrage wordt
											gevraagd een machtiging af te geven. Wanneer geen
											machtiging wordt verstrekt kan de jaarlijkse bijdrage
											middels overschrijving worden voldaan. Hiervoor worden
											extra administratiekosten in rekening gebracht (a €4,95
											per keer).
										</span>
										{showIban && (
											<div style={{ paddingTop: '16px' }}>
												<FormGroup>
													<FormControlLabel
														key={'directDebit'}
														value={checkedDirectDebit}
														control={
															<Checkbox
																checked={checkedDirectDebit}
																onChange={() =>
																	handleChangeDirectDebit()
																}
															/>
														}
														label={
															'Hierbij verleen ik het register MBT een machtiging voor automatische incasso van de jaarlijkse registratiebijdrage'
														}
													/>
												</FormGroup>
												<TextField
													style={{ width: '100%', marginTop: '16px' }}
													className="IBAN"
													variant="outlined"
													label="IBAN"
													value={IBANValue}
													placeholder="IBAN"
													onChange={handleChangeIBAN}
													error={!checkIban()}
												/>
											</div>
										)}
									</div>
								)}
						</div>
					</div>
				</FormControl>
			</DialogContent>
			<DialogActions style={{ padding: '16px 24px' }}>
				<div className={styling.ActionsContainer}>
					<Button
						disabled={!completeAgreement}
						variant="contained"
						size="small"
						onClick={() => {
							handleConfirm(IBANValue, checkedDirectDebit);
						}}
						endIcon={<Icon>send</Icon>}
					>
						Doorgaan
					</Button>
					<Button
						color="error"
						variant="contained"
						size="small"
						onClick={() => {
							handleClose();
						}}
						endIcon={<Icon>cancel</Icon>}
					>
						Annuleren
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
}
