import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './common/contexts/AuthContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AxiosInstanceProvider } from './common/contexts/AxiosContext';
import { SnackbarProvider } from 'notistack';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './ApplicationInsights';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: false,
		},
	},
});

root.render(
	<React.StrictMode>
		<AppInsightsContext.Provider value={reactPlugin}>
			<BrowserRouter>
				<SnackbarProvider>
					<AuthProvider>
						<AxiosInstanceProvider>
							<QueryClientProvider client={queryClient}>
								<App />
							</QueryClientProvider>
						</AxiosInstanceProvider>
					</AuthProvider>
				</SnackbarProvider>
			</BrowserRouter>
		</AppInsightsContext.Provider>
	</React.StrictMode>
);
