import styling from './_styling.module.scss';

interface IProps {
	title: string;
	size: string;
}

export function HeaderTitle(props: IProps) {
	const { title, size = '50px' } = props;

	return (
		<header className={styling.header}>
			<h1 className={styling.headerTitle} style={{ fontSize: size }}>
				{title}
			</h1>
		</header>
	);
}
