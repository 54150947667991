import { useEffect, useRef, useState } from 'react';
import { Table, TableBody, TableRow, TableCell, Icon, Button } from '@mui/material';
import IUploadReponse from '../../../../types/Attachments/UploadReponse';
import { useAttachments } from '../../../hooks/useAttachments';

import styling from './_styling.module.scss';

interface IProps {
	disabled?: boolean;
	deleteDisabled?: boolean;
	value: string;
	title: string;
	onValueChange: Function;
}

export function QuestionFieldAttachment({
	title,
	value,
	disabled,
	deleteDisabled = false,
	onValueChange,
}: IProps) {
	const { uploadAttachment, getAttachmentById, deleteAttachment } = useAttachments();
	const [file, setFile] = useState<string>('');
	const [fileDownloadUrl, setFileDownloadURL] = useState<string>('');
	const [fieldValue, setFieldValue] = useState<string[]>(value == '' ? [] : value.split(';'));
	const downloadRef = useRef<HTMLAnchorElement>(null);

	const inputRef = useRef<HTMLInputElement>(null);
	const handleOpenManager = () => {
		if (inputRef.current !== null) {
			// 👇️ open file input box on click of other element
			inputRef.current.click();
		}
	};

	useEffect(() => {
		onValueChange(fieldValue.join(';'));
	}, [fieldValue]);

	const handleChange = (event: any) => {
		const formData = new FormData();
		formData.append('file', event.target.files[0]);

		uploadAttachment(formData).then((response: IUploadReponse) => {
			setFieldValue((oldvalue) => {
				const tempValue = [...oldvalue];
				tempValue.push(`${response.identifier},${response.name}`);

				return tempValue;
			});
		});
	};

	function downloadAttachment(fileDataString: string) {
		const re: RegExp = /(?:\.([^.]+))?$/;
		let fileData: RegExpExecArray | null = null;
		let fileId: string = '';
		let fileName: string = '';
		if (fileDataString !== null) {
			fileId = fileDataString.split(',')[0];
			fileName = fileDataString.split(',')[1];
			fileData = re.exec(fileDataString);

			if (fileData !== null) {
				setFile(fileName);
			}
			getAttachmentById(fileId).then((blob) => {
				const url = window.URL.createObjectURL(new Blob([blob]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${fileName}`);
				document.body.appendChild(link);
				link.click();
				link.parentNode?.removeChild(link);
			});
		}
	}

	function deleteFile(fileDataString: string) {
		if (fileDataString !== null) {
			const fileId = fileDataString.split(',')[0];

			deleteAttachment(fileId).then(() => {
				setFieldValue((oldvalue) => {
					const tempValue = [...oldvalue];
					tempValue.splice(tempValue.indexOf(fileDataString), 1);

					return tempValue;
				});
			});
		}
	}

	return (
		<div className={styling.Root}>
			<span className={styling.Question}>{title}</span>
			<Table size="small">
				<TableBody>
					{fieldValue?.map((row: any, index) => (
						<TableRow key={row}>
							<TableCell key={index + row}>{row.split(',')[1]}</TableCell>
							<TableCell key={index + row + 'icons'} align="center">
								<Icon
									key={index + row + 'icon'}
									className="material-icons-outlined"
									style={{ cursor: 'pointer' }}
									onClick={() => {
										downloadAttachment(row);
									}}
								>
									visibility
								</Icon>
							</TableCell>
							{!deleteDisabled && (
								<TableCell>
									<Icon
										key={index + row + 'icon'}
										className="material-icons-outlined"
										style={{ cursor: 'pointer' }}
										onClick={() => {
											deleteFile(row);
										}}
									>
										deleteoutline
									</Icon>
								</TableCell>
							)}
						</TableRow>
					))}
				</TableBody>
			</Table>
			<input
				style={{ display: 'none' }}
				ref={inputRef}
				type="file"
				id="myFile"
				name="filename"
				title="test"
				placeholder="test"
				onChange={handleChange}
			/>
			<Button
				style={{ width: '100px' }}
				variant="contained"
				size="small"
				disabled={disabled ?? false}
				onClick={handleOpenManager}
			>
				Upload
			</Button>
			<a style={{ display: 'none' }} download={file} href={fileDownloadUrl} ref={downloadRef}>
				download it
			</a>
		</div>
	);
}
