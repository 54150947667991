import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderTitle } from '../../common/components/Atoms/HeaderTitle/HeaderTitle';
import { DataTable } from '../../common/components/Organisms/DataTable/DataTable';
import { DetailCard } from '../../common/components/Organisms/DetailCard/DetailCard';
import { useAccreditations } from '../../common/hooks/useAccreditations';
import { IPendingAccreditationsOverview } from '../../types/Accreditation/PendingAccreditationsOverview';
import ITableColumn from '../../types/TableColumn';

export function PendingAccreditations() {
	const { t } = useTranslation();

	const { getAccreditationsPending, loadingGet: getAccreditationsIsLoading } =
		useAccreditations();
	const [Accreditations, setAccreditations] = useState<IPendingAccreditationsOverview[]>([]);

	useEffect(() => {
		getAccreditationsPending().then((data) => {
			setAccreditations(data);
		});
	}, []);

	const columns: ITableColumn[] = [
		{ label: 'title', type: 'text' },
		{ label: 'requesterFirstName', type: 'text' },
		{ label: 'requesterMiddleName', type: 'text' },
		{ label: 'requesterLastName', type: 'text' },
		{ label: 'accreditationStatus', type: 'text' },
		{ label: 'submittedDate', type: 'date' },
	];

	return (
		<>
			<HeaderTitle size="50px" title={t('headerPendingAccreditations')} />
			<DetailCard headerTitle="" loading={getAccreditationsIsLoading}>
				<DataTable
					navigateTo={'Accreditation'}
					columns={columns}
					tableData={Accreditations}
				></DataTable>
			</DetailCard>
		</>
	);
}
