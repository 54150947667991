import { Icon, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ITableColumn from '../../../../types/TableColumn';

import styling from './_styling.module.scss';

interface IProps {
	columns: ITableColumn[];
	tableData: any[];
	navigateTo?: string;
}

export function DataTable(iProperties: IProps) {
	const { navigateTo, columns, tableData } = iProperties;

	const { t } = useTranslation();
	const navigate = useNavigate();

	function openDetails(id: string) {
		navigate(`/${navigateTo}/${id}`);
	}

	return (
		<Table>
			<TableHead data-test-hook="header">
				<TableRow>
					{columns.map((column) => {
						return (
							<TableCell key={column.label} size="small">
								{t(`${column.label}`)}
							</TableCell>
						);
					})}
					{!!navigateTo && <TableCell key="icons"></TableCell>}
				</TableRow>
			</TableHead>
			<TableBody>
				{!!tableData &&
					tableData?.map((row: any, index) => (
						<TableRow key={'row' + index}>
							{columns.map((column, index) => {
								return (
									<TableCell key={'column' + index} align={column.align}>
										{column.type === 'date'
											? row[column.label]
												? format(new Date(row[column.label]), 'dd-MM-yyyy')
												: ''
											: t(`${row[column.label] ?? ''}`)}
									</TableCell>
								);
							})}
							{!!navigateTo && (
								<TableCell key={row.id + 'icons'} align="right">
									<Icon
										className={`material-icons-outlined ${styling.cursorPointer}`}
										onClick={() => {
											openDetails(row.id);
										}}
									>
										visibility
									</Icon>
								</TableCell>
							)}
						</TableRow>
					))}
			</TableBody>
		</Table>
	);
}
