import { ReactNode } from 'react';
import { Frame } from '../Organisms/Frame/Frame';
import { Sidebar } from '../Organisms/Sidebar/Sidebar';

import styling from './_styling.module.scss';

interface IProps {
	children: ReactNode;
}

export function PageLayout({ children }: IProps) {
	return (
		<div className={styling.BasePage}>
			<div className={styling.SidebarContainer}>{<Sidebar />}</div>
			<div className={styling.ContentFrame}>{<Frame children={children} />}</div>
		</div>
	);
}
