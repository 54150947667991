import { useContext, useState } from 'react';
import { AxiosContext } from '../contexts/AxiosContext';
import IRegistrationTemplate from '../../types/Registration/RegistrationTemplate';
import ILoggedOnUser from '../../types/User/LoggedOnUser';

export const useRegistrationTemplates = () => {
	const axiosContext = useContext(AxiosContext);
	const [loading, setLoading] = useState(false);
	const [currentRegistrationTemplate, setCurrentRegistrationTemplate] =
		useState<IRegistrationTemplate>();

	function getRegistrationTemplateTypes() {
		setLoading(true);

		return axiosContext
			.instance!.get(`/registrationTemplates/`)
			.then((response) => response.data as string[])
			.finally(() => setLoading(false));
	}

	function getRegistrationTemplateByRegistrationType(registrationType: string) {
		setLoading(true);

		axiosContext
			.instance!.get(`/registrationTemplates/${registrationType}`)
			.then((response) =>
				setCurrentRegistrationTemplate(response.data as IRegistrationTemplate)
			)
			.finally(() => setLoading(false));
	}

	function getRegistrationTemplateByRegistrationId(id: string) {
		setLoading(true);

		axiosContext
			.instance!.get(`/registrationTemplates/registration/${id}`)
			.then((response) =>
				setCurrentRegistrationTemplate(response.data as IRegistrationTemplate)
			)
			.finally(() => setLoading(false));
	}

	function filterRegistrationTemplateTypes(data: string[], user: ILoggedOnUser) {
		if (!user.therapeut) data = data.filter((x) => x !== 'TherapeutReregister');
		if (!user.sociotherapeut) data = data.filter((x) => x !== 'SociotherapeutReregister');
		if (
			!user.supervisor &&
			!user.supervisorBasistherapeut &&
			!user.supervisorTherapeut &&
			!user.supervisorSociotherapeut &&
			!user.supervisorSysteemtherapeut &&
			!user.supervisorVaktherapeut
		)
			data = data.filter((x) => x !== 'SupervisorReregister');
		if (!user.systeemtherapeut) data = data.filter((x) => x !== 'SysteemtherapeutReregister');
		if (!user.vaktherapeut) data = data.filter((x) => x !== 'VaktherapeutReregister');

		return data;
	}

	return {
		getRegistrationTemplateTypes,
		getRegistrationTemplateByRegistrationType,
		getRegistrationTemplateByRegistrationId,
		filterRegistrationTemplateTypes,
		currentRegistrationTemplate,
		loading,
	};
};
