import { useContext, useState } from 'react';
import { AxiosContext } from '../contexts/AxiosContext';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const usePayments = () => {
	const axiosContext = useContext(AxiosContext);
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState(false);

	const createPayment = () => {
		setLoading(true);
		return axiosContext
			.instance!.post(`/payment`)
			.then((response) => {
				navigate(`/${response.data.url}`);
			})
			.catch(() => {})
			.finally(() => setLoading(false));
	};

	const getPayment = (id: string) => {
		setLoading(true);
		return axiosContext
			.instance!.get(`/payment/${id}`)
			.then((response) => {
				window.location.replace(response.data);
			})
			.catch(() => {})
			.finally(() => setLoading(false));
	};

	return {
		createPayment,
		getPayment,
		loading,
	};
};
