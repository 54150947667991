import { CircularProgress, Box, Alert, Fab, Icon, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { HeaderTitle } from '../../common/components/Atoms/HeaderTitle/HeaderTitle';
import { DetailCard } from '../../common/components/Organisms/DetailCard/DetailCard';
import { useAccreditations } from '../../common/hooks/useAccreditations';
import { DataTable } from '../../common/components/Organisms/DataTable/DataTable';
import IAccreditation from '../../types/Accreditation/Accreditation';
import { useUsers } from '../../common/hooks/useUsers';
import ITableColumn from '../../types/TableColumn';

export function Accreditations() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { isUserProfileComplete } = useUsers();
	const { getAccreditationsAll, loadingGet: getAccreditationsIsLoading } = useAccreditations();

	const [userProfileComplete, setUserProfileComplete] = useState<boolean>(false);
	const [tableAccreditations, setTableAccreditations] = useState<IAccreditation[]>();

	useEffect(() => {
		isUserProfileComplete().then((data) => {
			setUserProfileComplete(data.data);
		});
		getAccreditationsAll().then((data) => {
			setTableAccreditations(data);
		});
	}, []);

	const columns: ITableColumn[] = [
		{ label: 'title', type: 'text' },
		{ label: 'accreditationStatus', type: 'text' },
		{ label: 'accreditationPoints', type: 'text' },
		{ label: 'accreditationDate', type: 'date' },
		{ label: 'expirationDate', type: 'date' },
	];

	return (
		<>
			<HeaderTitle size="50px" title={t('headerMyAccreditations')} />
			<DetailCard headerTitle="" loading={getAccreditationsIsLoading}>
				<DataTable
					navigateTo={'Accreditation'}
					columns={columns}
					tableData={tableAccreditations as IAccreditation[]}
				></DataTable>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-end',
						gridGap: '16px',
						padding: '10px 0',
					}}
				>
					{userProfileComplete ? null : (
						<Alert severity="warning">
							{t('textUserProfileIncompleteAccreditation')}
						</Alert>
					)}
					<Button
						variant="contained"
						disableElevation
						disabled={!userProfileComplete}
						id="addAccreditation-button"
						onClick={() => {
							navigate(`/accreditation`);
						}}
					>
						{t('functionAdd')}
					</Button>
				</div>
			</DetailCard>
		</>
	);
}
