import { Navigate, Route, Routes } from 'react-router-dom';
import { RequireAuth } from '../common/auth/RequireAuth';
import Login from './Users/Login';
import { Registrations } from './Registrations/Registrations';
import { Registration } from './Registrations/Registration';
import { UsersInformation } from './Users/Users';
import { OpenRegistrations } from './Registrations/OpenRegistrations';
import { Dashboard } from './Dashboard';
import { UserProfile } from './Users/UserProfile';
import UserRegister from './Users/UserRegister';
import UserConfirm from './Users/UserConfirm';
import { Accreditation } from './Accreditations/Accreditation';
import { Accreditations } from './Accreditations/Accreditations';
import { OpenAccreditations } from './Accreditations/OpenAccreditations';
import Payment from './Payment';
import { PendingRegistrations } from './Registrations/PendingRegistrations';
import { ArchivedRegistrations } from './Registrations/ArchivedRegistrations';
import { ArchivedAccreditations } from './Accreditations/ArchivedAccreditations';
import { PendingAccreditations } from './Accreditations/PendingAccreditations';
import PasswordReset from './Users/PasswordReset';
import Register from './Register';
import Trainings from './Trainings';
import ForgotPassword from './Users/ForgotPassword';
import { Admin } from './Admin/Admin';

export function AppRoutes() {
	return (
		<Routes>
			<Route
				path="/dashboard"
				element={
					<RequireAuth>
						<Dashboard />
					</RequireAuth>
				}
			></Route>
			<Route
				path="/my-accreditations"
				element={
					<RequireAuth>
						<Accreditations />
					</RequireAuth>
				}
			></Route>
			<Route
				path="/archived-accreditations"
				element={
					<RequireAuth requiredRoles={['Commission']}>
						<ArchivedAccreditations />
					</RequireAuth>
				}
			></Route>
			<Route
				path="/open-accreditations"
				element={
					<RequireAuth requiredRoles={['Admin']}>
						<OpenAccreditations />
					</RequireAuth>
				}
			></Route>
			<Route
				path="/pending-accreditations"
				element={
					<RequireAuth requiredRoles={['Commission']}>
						<PendingAccreditations />
					</RequireAuth>
				}
			></Route>
			<Route
				path="/my-registrations"
				element={
					<RequireAuth>
						<Registrations />
					</RequireAuth>
				}
			></Route>
			<Route
				path="/open-registrations"
				element={
					<RequireAuth requiredRoles={['Admin']}>
						<OpenRegistrations />
					</RequireAuth>
				}
			></Route>
			<Route
				path="/archived-registrations"
				element={
					<RequireAuth requiredRoles={['Commission']}>
						<ArchivedRegistrations />
					</RequireAuth>
				}
			></Route>
			<Route
				path="/pending-registrations"
				element={
					<RequireAuth requiredRoles={['Commission']}>
						<PendingRegistrations />
					</RequireAuth>
				}
			></Route>
			<Route
				path="/users"
				element={
					<RequireAuth requiredRoles={['Admin']}>
						<UsersInformation />
					</RequireAuth>
				}
			></Route>
			<Route
				path="/user-profile/:id"
				element={
					<RequireAuth>
						<UserProfile />
					</RequireAuth>
				}
			></Route>
			<Route
				path="/user-profile"
				element={
					<RequireAuth>
						<UserProfile />
					</RequireAuth>
				}
			></Route>
			<Route
				path="/accreditation/:id"
				element={
					<RequireAuth>
						<Accreditation />
					</RequireAuth>
				}
			></Route>
			<Route
				path="/accreditation"
				element={
					<RequireAuth>
						<Accreditation />
					</RequireAuth>
				}
			></Route>

			<Route
				path="/registration/:id"
				element={
					<RequireAuth>
						<Registration />
					</RequireAuth>
				}
			></Route>
			<Route
				path="/registration"
				element={
					<RequireAuth>
						<Registration />
					</RequireAuth>
				}
			></Route>
			<Route
				path="/admin"
				element={
					<RequireAuth requiredRoles={['Admin']}>
						<Admin />
					</RequireAuth>
				}
			></Route>
			<Route path="/payment" element={<Payment />}></Route>
			<Route path="/login" element={<Login />}></Route>
			<Route path="/logout" element={<Navigate to="/login" replace />}></Route>
			<Route path="/user-register" element={<UserRegister />}></Route>
			<Route path="/email-confirm" element={<UserConfirm />}></Route>
			<Route path="/forgot-password" element={<ForgotPassword />}></Route>
			<Route path="/reset-password" element={<PasswordReset />}></Route>
			<Route path="/register" element={<Register />}></Route>
			<Route path="/trainings" element={<Trainings />}></Route>
			<Route path="*" element={<Navigate to="/dashboard" replace />} />
		</Routes>
	);
}

export const SidebarMenuItems = [
	{
		icon: 'dashboard',
		name: 'Dashboard',
		route: '/dashboard',
		requiredRoles: [],
	},
	{
		icon: 'app_registration',
		name: 'Mijn accreditaties',
		route: 'my-accreditations',
		userType: ['accreditation'],
	},
	{
		icon: 'app_registration',
		name: 'Gearchiveerde accreditaties',
		route: 'archived-accreditations',
		requiredRoles: ['Commission'],
	},
	{
		icon: 'app_registration',
		name: 'Aangevraagde accreditaties',
		route: 'pending-accreditations',
		requiredRoles: ['Commission'],
	},
	{
		icon: 'app_registration',
		name: 'Open accreditaties',
		route: 'open-accreditations',
		requiredRoles: ['Admin'],
	},
	{
		icon: 'app_registration',
		name: 'Mijn registraties',
		route: 'my-registrations',
		userType: ['registration'],
	},
	{
		icon: 'app_registration',
		name: 'Gearchiveerde registraties',
		route: 'archived-registrations',
		requiredRoles: ['Commission'],
	},
	{
		icon: 'app_registration',
		name: 'Aangevraagde registraties',
		route: 'pending-registrations',
		requiredRoles: ['Commission'],
	},
	{
		icon: 'app_registration',
		name: 'Open registraties',
		route: 'open-registrations',
		requiredRoles: ['Admin'],
	},
	{
		icon: 'group',
		name: 'Gebruikers',
		route: '/users',
		requiredRoles: ['Admin'],
	},
	{
		icon: 'account_box',
		name: 'Profiel',
		route: '/user-profile',
		requiredRoles: [],
	},
	{
		icon: 'account_box',
		name: 'Admin',
		route: '/admin',
		requiredRoles: ['Admin'],
	},
	{
		icon: 'logout',
		name: 'Uitloggen',
		route: 'logout',
		requiredRoles: [],
	},
];
