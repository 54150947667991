import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, Icon, TextField } from '@mui/material';

import useAuth from '../../common/hooks/useAuth';
import { useRegistrationTemplates } from '../../common/hooks/useRegistrationTemplates';
import { useRegistrations } from '../../common/hooks/useRegistrations';

import IRegistration from '../../types/Registration/Registration';
import IQuestion from '../../types/Question';

import { HeaderTitle } from '../../common/components/Atoms/HeaderTitle/HeaderTitle';
import { QuestionFieldText } from '../../common/components/Atoms/QuestionFields/QuestionFieldText';
import { QuestionFieldAttachment } from '../../common/components/Atoms/QuestionFields/QuestionFieldAttachment';
import { QuestionFieldSelect } from '../../common/components/Atoms/QuestionFields/QuestionFieldSelect';
import { QuestionFieldRadio } from '../../common/components/Atoms/QuestionFields/QuestionFieldRadio';
import { DetailCard } from '../../common/components/Organisms/DetailCard/DetailCard';
import { TextFieldPopup } from '../../common/components/Atoms/Popups/TextFieldPopup';
import IRegistrationComment from '../../types/Registration/RegistrationComment';
import { DataTable } from '../../common/components/Organisms/DataTable/DataTable';
import { ConfirmRegistrationPopup } from '../../common/components/Atoms/Popups/ConfirmRegistrationPopup';
import ITableColumn from '../../types/TableColumn';
import { SupervisorSearchPopup } from '../../common/components/Atoms/Popups/SupervisorSearchPopup';
import { usePayments } from '../../common/hooks/usePayments';
import { SelectSuperVisionPopup } from '../../common/components/Atoms/Popups/SelectSupervisionPopup';

export function Registration() {
	const { id } = useParams();
	const { t } = useTranslation();
	const { getPayment } = usePayments();
	const navigate = useNavigate();
	const { user, isInRole } = useAuth();
	const commentColumns: ITableColumn[] = [
		{ label: 'commentDate', type: 'date' },
		{ label: 'commenterName', type: 'text' },
		{ label: 'comment', type: 'text' },
	];
	const {
		getRegistrationTemplateByRegistrationId,
		getRegistrationTemplateByRegistrationType,
		loading: templateLoading,
		currentRegistrationTemplate,
	} = useRegistrationTemplates();
	const {
		getRegistrationById,
		updateRegistration,
		updateRegistrationStatus,
		setSupervisorState,
		deleteRegistrationById,
		acceptSupervisorRegistration,
		UploadComment,
		submitRegistration,
		loadingGet: getRegistrationsIsLoading,
		loadingUpdate: updateRegistrationsIsLoading,
	} = useRegistrations();

	const [registration, setRegistration] = useState<IRegistration>();
	const [registrationIsComplete, setRegistrationIsComplete] = useState<boolean>(false);

	const [stateFinalComment, setStateFinalComment] = useState(false);
	const [comments, setComments] = useState<IRegistrationComment[]>([]);

	//popup states
	const [statePopupTextField, setStatePopupTextField] = useState(false);
	const handleTogglePopupTextField = (state: boolean) => {
		setStateFinalComment(state);
		setStatePopupTextField((prevOpen) => !prevOpen);
	};

	const [statePopupConfirmation, setStatePopupConfirmation] = useState(false);
	const handleToggleConfirmationPopup = () => {
		setStatePopupConfirmation((prevOpen) => !prevOpen);
	};

	const [statePopupSupervisor, setStatePopupSupervisor] = useState(false);
	const handleToggleSupervisorPopup = () => {
		setStatePopupSupervisor((prevOpen) => !prevOpen);
	};

	const [statePopupSupervision, setStatePopupSupervision] = useState(false);
	const handleToggleStatePopupSupervision = () => {
		setStatePopupSupervision((prevOpen) => !prevOpen);
	};

	// Only if new registration is requested:
	const newRegistration: IRegistration = {
		registrationType: '',
		registrationStatus: '',
		requesterId: '',
		answers: [],
	};

	useEffect(() => {
		const regex = new RegExp(
			/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
		);

		if (id !== undefined) {
			if (regex.test(id)) {
				getRegistrationTemplateByRegistrationId(id);
				getRegistrationById(id).then((data) => {
					setComments(data.comments as IRegistrationComment[]);
					setRegistration(data);
				});
			} else {
				getRegistrationTemplateByRegistrationType(id);
				newRegistration.registrationType = id;
				newRegistration.registrationStatus = 'Concept';
				newRegistration.requesterId = user?.id as string;
				setRegistration(newRegistration);
			}
			CheckCompletion();
		}
	}, []);

	useEffect(() => {
		CheckCompletion();
	}, [registration]);

	function CheckCompletion() {
		let returnValue = true;

		currentRegistrationTemplate?.questionList.forEach((question) => {
			if (!isNaN(+question.order)) {
				const questionAnswer = registration?.answers.find(
					(a) => a.questionId === question.id
				)?.answer;
				if (
					!question.supervisor &&
					(questionAnswer === undefined || questionAnswer === '')
				) {
					returnValue = false;
				} else if (
					question.supervisor &&
					registration?.registrationStatus === 'Supervisor' &&
					(questionAnswer === undefined || questionAnswer === '')
				) {
					returnValue = false;
				}
			}
		});
		setRegistrationIsComplete(returnValue);
	}

	function onValueChanged(newValue: string, question: IQuestion) {
		setRegistration((oldRegistration) => {
			let tempRegistration = { ...oldRegistration } as IRegistration;

			let answer = tempRegistration?.answers.find((x) => x.questionId == question.id);
			if (answer) {
				answer.answer = newValue;
			} else {
				tempRegistration?.answers.push({ questionId: question.id, answer: newValue });
			}

			return tempRegistration;
		});
	}

	function deleteRegistration() {
		deleteRegistrationById(registration!.id as string).then(() =>
			navigate('/my-registrations')
		);
	}

	function updateStatus(status: string) {
		if (registration !== undefined) {
			if (
				(registration.registrationType === 'Therapeut' ||
					registration.registrationType === 'SocioTherapeut' ||
					registration.registrationType === 'VakTherapeut' ||
					registration.registrationType === 'SysteemTherapeut') &&
				registration.registrationStatus === 'Pending' &&
				status === 'Accepted'
			) {
				handleToggleSupervisorPopup();
			} else if (
				registration.registrationType === 'Supervisor' &&
				registration.registrationStatus === 'Pending' &&
				status === 'Accepted'
			) {
				setSupervision();
			} else if (
				registration.registrationType === 'Supervisor' &&
				registration.registrationStatus === 'SupervisionAccepted'
			) {
				handleToggleStatePopupSupervision();
			} else {
				updateRegistrationStatus(registration.id as string, status).then(() =>
					navigate('/open-registrations')
				);
			}
		}
	}

	function setSupervisor(supervisorId: string) {
		if (registration !== undefined) {
			setSupervisorState(registration.id as string, supervisorId).then(() =>
				navigate('/open-registrations')
			);
		}
	}

	function setSupervision() {
		if (registration !== undefined) {
			updateRegistrationStatus(registration.id as string, 'SupervisionState').then(() =>
				navigate('/open-registrations')
			);
		}
	}

	function isQuestionDisabled(supervisorQuestion: boolean) {
		if (
			(registration?.registrationStatus === 'SupervisorState' ||
				registration?.registrationStatus === 'SupervisionState') &&
			supervisorQuestion
		)
			return false;

		return (
			registration?.registrationStatus !== 'Concept' &&
			registration?.registrationStatus !== 'ExtraInfoNeeded'
		);
	}

	function doPayment(id: string | undefined) {
		if (id !== undefined) getPayment(id);
	}

	function renderComments() {
		return (
			<>
				<DataTable
					columns={commentColumns}
					tableData={comments as IRegistrationComment[]}
				></DataTable>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-end',
						gridGap: '16px',
						padding: '10px 0',
					}}
				>
					<Button
						variant="contained"
						className="stdButton"
						onClick={() => {
							handleTogglePopupTextField(false);
						}}
					>
						Toevoegen
					</Button>
				</div>
			</>
		);
	}

	function renderGroupHeader(index: number) {
		return currentRegistrationTemplate?.questionList[index - 1] == undefined ||
			(currentRegistrationTemplate?.questionList[index - 1] != undefined &&
				currentRegistrationTemplate?.questionList[index - 1].group !=
					currentRegistrationTemplate?.questionList[index].group) ? (
			<HeaderTitle
				size="20px"
				title={currentRegistrationTemplate?.questionList[index].group as string}
			/>
		) : null;
	}

	function renderBooleanField(question: IQuestion) {
		return (
			<QuestionFieldRadio
				disabled={isQuestionDisabled(question.supervisor)}
				question={question}
				value={
					registration?.answers.find((x) => x.questionId === question.id)?.answer ?? ''
				}
				onValueChange={(e: string) => onValueChanged(e, question)}
			></QuestionFieldRadio>
		);
	}

	function renderAttachmentField(question: IQuestion) {
		let title = '';
		if (!isNaN(+question.order)) title += `${question.order}  `;
		title += question.text;

		return (
			<QuestionFieldAttachment
				disabled={isQuestionDisabled(question.supervisor)}
				deleteDisabled={isQuestionDisabled(question.supervisor)}
				title={title}
				value={
					registration?.answers.find((x) => x.questionId === question.id)?.answer ?? ''
				}
				onValueChange={(e: string) => {
					onValueChanged(e, question);
				}}
			></QuestionFieldAttachment>
		);
	}

	function renderTextField(index: number, question: IQuestion) {
		if (question.order.endsWith('a')) {
			return (
				registration?.answers
					.find(
						(x) =>
							x.questionId === currentRegistrationTemplate?.questionList[index - 1].id
					)
					?.answer.includes('Anders') && (
					<QuestionFieldText
						disabled={isQuestionDisabled(question.supervisor)}
						question={question}
						value={
							registration?.answers.find((x) => x.questionId === question.id)
								?.answer ?? ''
						}
						onValueChange={(e: string) => onValueChanged(e, question)}
					></QuestionFieldText>
				)
			);
		} else {
			return (
				<QuestionFieldText
					disabled={isQuestionDisabled(question.supervisor)}
					question={question}
					value={
						registration?.answers.find((x) => x.questionId === question.id)?.answer ??
						''
					}
					onValueChange={(e: string) => onValueChanged(e, question)}
				></QuestionFieldText>
			);
		}
	}

	function renderSelectField(question: IQuestion) {
		return (
			<QuestionFieldSelect
				disabled={isQuestionDisabled(question.supervisor)}
				question={question}
				value={
					registration?.answers.find((x) => x.questionId === question.id)?.answer ?? ''
				}
				onValueChange={(e: string) => onValueChanged(e, question)}
			></QuestionFieldSelect>
		);
	}

	function renderRequesterInfo() {
		return (
			<div style={{ display: 'grid', columnGap: '5px', rowGap: '13px' }}>
				<div style={{ display: 'flex', columnGap: '5px', maxWidth: '595px' }}>
					<TextField
						style={{ width: '100%' }}
						required
						variant="outlined"
						size="small"
						id="firstName"
						label="Voornaam"
						placeholder=""
						value={registration?.requesterFirstName || ''}
						disabled
					/>
				</div>
				<div style={{ display: 'flex', columnGap: '5px', maxWidth: '595px' }}>
					<TextField
						style={{ width: '100%' }}
						variant="outlined"
						size="small"
						id="middleName"
						label="Tussenvoegsel"
						placeholder=""
						value={registration?.requesterMiddleName || ''}
						disabled
					/>
				</div>
				<div style={{ display: 'flex', columnGap: '5px', maxWidth: '595px' }}>
					<TextField
						style={{ width: '100%' }}
						required
						variant="outlined"
						size="small"
						id="lastName"
						label="Achternaam"
						placeholder=""
						value={registration?.requesterLastName || ''}
						disabled
					/>
				</div>
				<div style={{ display: 'flex', columnGap: '5px', maxWidth: '595px' }}>
					<TextField
						style={{ width: '100%' }}
						required
						variant="outlined"
						size="small"
						id="lastName"
						label="E-mail"
						placeholder=""
						value={registration?.requesterEmail || ''}
						disabled
					/>
				</div>
			</div>
		);
	}

	function renderCommissionButtons() {
		return (
			isInRole('Commission') && (
				<>
					{(registration?.registrationStatus === 'Pending' ||
						registration?.registrationStatus === 'SupervisionAccepted' ||
						registration?.registrationStatus === 'SupervisorAccepted') && (
						<>
							<Button
								color="success"
								variant="contained"
								size="small"
								onClick={() => {
									updateStatus('Accepted');
								}}
								endIcon={
									updateRegistrationsIsLoading ? (
										<CircularProgress
											style={{
												width: '18px',
												height: '18px',
											}}
											color="inherit"
										/>
									) : (
										<Icon>checkcircleoutline</Icon>
									)
								}
							>
								Goedkeuren
							</Button>
							{registration?.registrationStatus === 'Pending' && (
								<Button
									color="warning"
									variant="contained"
									size="small"
									onClick={() => {
										handleTogglePopupTextField(true);
									}}
									endIcon={
										updateRegistrationsIsLoading ? (
											<CircularProgress
												style={{
													width: '18px',
													height: '18px',
												}}
												color="inherit"
											/>
										) : (
											<Icon>warning</Icon>
										)
									}
								>
									Extra informatie vragen
								</Button>
							)}
							<Button
								color="error"
								variant="contained"
								size="small"
								onClick={() => {
									updateStatus('Rejected');
								}}
								endIcon={
									updateRegistrationsIsLoading ? (
										<CircularProgress
											style={{
												width: '18px',
												height: '18px',
											}}
											color="inherit"
										/>
									) : (
										<Icon>cancel</Icon>
									)
								}
							>
								Afkeuren
							</Button>
						</>
					)}
				</>
			)
		);
	}

	function renderAdminButtons() {
		return (
			isInRole('Admin') && (
				<>
					{registration?.registrationStatus === 'PendingAdmin' && (
						<>
							<Button
								color="success"
								variant="contained"
								size="small"
								onClick={() => {
									updateStatus('Pending');
								}}
								endIcon={
									updateRegistrationsIsLoading ? (
										<CircularProgress
											style={{
												width: '18px',
												height: '18px',
											}}
											color="inherit"
										/>
									) : (
										<Icon>checkcircleoutline</Icon>
									)
								}
							>
								Doorzetten naar CUR
							</Button>
							<Button
								color="warning"
								variant="contained"
								size="small"
								onClick={() => {
									handleTogglePopupTextField(true);
								}}
								endIcon={
									updateRegistrationsIsLoading ? (
										<CircularProgress
											style={{
												width: '18px',
												height: '18px',
											}}
											color="inherit"
										/>
									) : (
										<Icon>warning</Icon>
									)
								}
							>
								Extra informatie vragen
							</Button>
						</>
					)}
					{registration?.registrationStatus !== 'Concept' && (
						<Button
							color="error"
							variant="contained"
							size="small"
							onClick={() => {
								updateStatus('Cancelled');
							}}
							endIcon={
								updateRegistrationsIsLoading ? (
									<CircularProgress
										style={{
											width: '18px',
											height: '18px',
										}}
										color="inherit"
									/>
								) : (
									<Icon>checkcircleoutline</Icon>
								)
							}
						>
							Aanvraag annuleren
						</Button>
					)}
				</>
			)
		);
	}

	function renderUserButtons() {
		return (
			(registration?.registrationStatus === 'Concept' ||
				registration?.requesterId === user?.id) && (
				<>
					{(registration?.registrationStatus === 'Concept' ||
						registration?.registrationStatus === 'SupervisorState' ||
						registration?.registrationStatus === 'SupervisionState' ||
						registration?.registrationStatus === 'ExtraInfoNeeded') && (
						<Button
							variant="outlined"
							size="small"
							onClick={() => {
								updateRegistration(registration).then((response) => {
									setRegistration(response);
								});
							}}
							endIcon={
								updateRegistrationsIsLoading && (
									<CircularProgress
										style={{ width: '18px', height: '18px' }}
										color="inherit"
									/>
								)
							}
						>
							Opslaan
						</Button>
					)}
					{registration?.registrationStatus === 'Payment' && (
						<Button
							variant="outlined"
							size="small"
							onClick={() => {
								doPayment(registration.id);
							}}
							endIcon={
								updateRegistrationsIsLoading && (
									<CircularProgress
										style={{ width: '18px', height: '18px' }}
										color="inherit"
									/>
								)
							}
						>
							Betalen
						</Button>
					)}
					{registration?.registrationStatus === 'Concept' ||
					registration?.registrationStatus === 'SupervisorState' ||
					registration?.registrationStatus === 'SupervisionState' ||
					registration?.registrationStatus === 'ExtraInfoNeeded' ? (
						<Button
							disabled={!registrationIsComplete}
							variant="contained"
							size="small"
							onClick={() => {
								updateRegistration(registration).then((response) => {
									setRegistration(response);
									handleToggleConfirmationPopup();
								});
							}}
							endIcon={
								updateRegistrationsIsLoading ? (
									<CircularProgress
										style={{ width: '18px', height: '18px' }}
										color="inherit"
									/>
								) : (
									<Icon>send</Icon>
								)
							}
						>
							Indienen
						</Button>
					) : null}
					{registration?.registrationStatus === 'Concept' ? (
						<Button
							color="error"
							variant="contained"
							size="small"
							onClick={() => {
								deleteRegistration();
							}}
							startIcon={
								updateRegistrationsIsLoading ? (
									<CircularProgress
										style={{ width: '18px', height: '18px' }}
										color="inherit"
									/>
								) : (
									<Icon>delete</Icon>
								)
							}
						>
							Verwijderen
						</Button>
					) : null}
				</>
			)
		);
	}

	return (
		<>
			{templateLoading ? (
				<Box sx={{ display: 'flex' }}>
					<CircularProgress color="inherit" />
				</Box>
			) : (
				<>
					<HeaderTitle size="50px" title={t('headerRegistration')} />
					{getRegistrationsIsLoading ? (
						<Box sx={{ display: 'flex' }}>
							<CircularProgress color="inherit" />
						</Box>
					) : (
						<div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
							{isInRole('Commission') ||
							(!!registration &&
								!!registration.comments?.length &&
								registration.comments?.length != 0) ? (
								<DetailCard headerTitle="Commentaar">{renderComments()}</DetailCard>
							) : null}

							{(isInRole('Commission') || isInRole('Admin')) &&
								registration &&
								registration.registrationStatus != '' &&
								registration.registrationStatus != 'Concept' && (
									<DetailCard headerTitle="Gegevens aanvrager">
										{renderRequesterInfo()}
									</DetailCard>
								)}
							<DetailCard headerTitle={t(registration?.registrationType as string)}>
								{currentRegistrationTemplate?.questionList.map(
									(question, index) => {
										if (
											question.supervisor &&
											(registration?.registrationStatus === 'Concept' ||
												registration?.registrationStatus ===
													'ExtraInfoNeeded' ||
												registration?.registrationStatus === 'Pending' ||
												registration?.registrationStatus === 'PendingAdmin')
										)
											return null;
										else
											return (
												<div key={index + 1}>
													{renderGroupHeader(index)}
													{question.type === 'attachment' &&
														renderAttachmentField(question)}
													{question.type === 'bool' &&
														renderBooleanField(question)}
													{question.type === 'select' &&
														renderSelectField(question)}
													{question.type === 'text' &&
														renderTextField(index, question)}
												</div>
											);
									}
								)}
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'flex-start',
										gridGap: '16px',
										padding: '10px 0',
									}}
								>
									{renderUserButtons()}
									{renderCommissionButtons()}
									{renderAdminButtons()}
								</div>
							</DetailCard>
						</div>
					)}
				</>
			)}
			<TextFieldPopup
				openDialog={statePopupTextField}
				headerTitle="Commentaar toevoegen:"
				handleClose={() => {
					handleTogglePopupTextField(false);
				}}
				handleConfirm={(text: string) => {
					if (!!registration && !!registration.id && !!user) {
						UploadComment(registration.id, {
							commenterName: isInRole('Commission')
								? 'CUR'
								: [user.firstName, user.middleName, user.lastName]
										.filter(Boolean)
										.join(' '),
							comment: text,
						}).then((result) => {
							setRegistration((oldRegistration) => {
								let tempRegistration = { ...oldRegistration } as IRegistration;
								tempRegistration.comments = result.comments;
								return tempRegistration;
							});
						});
						setComments((oldvalue) => {
							const tempValue = [...oldvalue];
							tempValue.push({
								commentDate: new Date(),
								commenterName: isInRole('Commission')
									? 'CUR'
									: [user.firstName, user.middleName, user.lastName]
											.filter(Boolean)
											.join(' '),
								comment: text,
								id: '',
								RegistrationId: '',
							});

							return tempValue;
						});
					}
					if (stateFinalComment) {
						updateStatus('ExtraInfoNeeded');
					}
					handleTogglePopupTextField(false);
				}}
			/>
			<ConfirmRegistrationPopup
				registrationStatus={registration?.registrationStatus}
				registrationType={registration?.registrationType}
				showIban={registration?.registrationType === 'BasisTherapeut'}
				openDialog={statePopupConfirmation}
				headerTitle="Registratie indienen:"
				handleClose={() => {
					handleToggleConfirmationPopup();
				}}
				handleConfirm={(accountNumber: string, checkedDirectDebit: boolean) => {
					if (!!registration && !!registration.id) {
						submitRegistration(
							registration.id,
							checkedDirectDebit ? accountNumber : ''
						).then((result) => {
							if (result) window.location.replace(result);
							else navigate('/registrations');
						});
					}
					handleToggleConfirmationPopup();
				}}
			/>
			<SelectSuperVisionPopup
				openDialog={statePopupSupervision}
				handleClose={() => {
					handleToggleStatePopupSupervision();
				}}
				handleConfirm={(
					systeemtherapeutSupervisor: boolean,
					vaktherapeutSupervisor: boolean,
					sociotherapeutSupervisor: boolean,
					therapeutSupervisor: boolean
				) => {
					acceptSupervisorRegistration(
						registration!.id!,
						systeemtherapeutSupervisor,
						vaktherapeutSupervisor,
						sociotherapeutSupervisor,
						therapeutSupervisor
					).then(() => {
						navigate('/pending-registrations');
					});

					handleToggleStatePopupSupervision();
				}}
			></SelectSuperVisionPopup>
			<SupervisorSearchPopup
				openDialog={statePopupSupervisor}
				headerTitle="Supervisor toewijzen"
				handleClose={() => {
					handleToggleSupervisorPopup();
				}}
				handleConfirm={(supervisorId: string) => {
					setSupervisor(supervisorId);
					handleToggleSupervisorPopup();
				}}
			/>
		</>
	);
}
