import { Icon, Button, Dialog, DialogActions, DialogContent, FormControl } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';
import ITableColumn from '../../../../types/TableColumn';

import styling from './_styling.module.scss';

interface IProps {
	openDialog: boolean;
	handleClose: Function;
	handleConfirm: Function;
	expirationDate?: Date;
}

export function AddEditionPopup({
	openDialog = false,
	handleClose,
	handleConfirm,
	expirationDate,
}: IProps) {
	const [dates, setDates] = useState<Date[]>([]);
	const [dateValue, setDateValue] = useState<Date | null>(null);

	const editionColumns: ITableColumn[] = [{ label: 'date', type: 'date' }];

	//hooks
	useEffect(() => {
		if (openDialog) {
			setDates([]);
		}
	}, [openDialog]);

	//functions
	function addDate() {
		if (dateValue) {
			setDates([...dates, dateValue]);
			setDateValue(null);
		}
	}

	function updateDate(value: Date | null) {
		setDateValue(value);
	}

	function getMaxDate() {
		if (expirationDate) return new Date(expirationDate);
		else
			return new Date(
				new Date().getFullYear() + 3,
				new Date().getMonth(),
				new Date().getDate()
			);
	}

	return (
		<Dialog open={openDialog} scroll="paper">
			<div className={styling.HeaderContainer}>
				<h1 className={styling.HeaderTitle}>Test</h1>
			</div>
			<DialogContent dividers={true}>
				Lesdagen:
				{dates.map((date, key) => (
					<div key={key}>{date.toLocaleDateString()}</div>
				))}
				<p>
					Vul hieronder de data in voor de lesdagen en klik op 'Toevoegen' om een lesdag
					toe te voegen.
				</p>
				<FormControl
					component="fieldset"
					variant="standard"
					style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '10px' }}
				>
					<DatePicker
						disablePast
						format="dd-MM-yyyy"
						label=""
						value={dateValue}
						maxDate={getMaxDate()}
						onChange={updateDate}
					/>
					<Button
						id="composition-button"
						variant="contained"
						className="stdButton"
						onClick={() => {
							addDate();
						}}
					>
						Toevoegen
					</Button>
				</FormControl>
			</DialogContent>
			<DialogActions style={{ padding: '16px 24px' }}>
				<div className={styling.ActionsContainer}>
					<Button
						disabled={dates.length === 0}
						variant="contained"
						size="small"
						onClick={() => {
							handleConfirm(dates);
						}}
						endIcon={<Icon>send</Icon>}
					>
						Opslaan
					</Button>
					<Button
						color="error"
						variant="contained"
						size="small"
						onClick={() => {
							handleClose();
						}}
						endIcon={<Icon>cancel</Icon>}
					>
						Annuleren
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
}
