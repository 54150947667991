import { Button } from '@mui/material';
import { HeaderTitle } from '../../common/components/Atoms/HeaderTitle/HeaderTitle';
import { DetailCard } from '../../common/components/Organisms/DetailCard/DetailCard';
import { ConfirmationPopup } from '../../common/components/Atoms/Popups/ConfirmationPopup';
import { useContext, useState } from 'react';
import { AxiosContext } from '../../common/contexts/AxiosContext';

export function Admin() {
	const axiosContext = useContext(AxiosContext);
	const [loading, setLoading] = useState(false);
	const [stateConfirmationPopup, setStateConfirmationPopup] = useState(false);
	const handleToggleConfirmationopup = () => {
		setStateConfirmationPopup((prevOpen) => !prevOpen);
	};

	function sendContributionRequest() {
		setLoading(true);
		return axiosContext
			.instance!.post(`/payment/yearly-invoice`)
			.finally(() => setLoading(false));
	}

	return (
		<>
			<HeaderTitle size="50px" title="Profiel" />
			<div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
				<DetailCard headerTitle="Secretariaat">
					<div id="adminSettings">
						<Button
							disabled={loading}
							variant="outlined"
							size="small"
							onClick={() => {
								handleToggleConfirmationopup();
							}}
						>
							Contributiefacturen verzenden
						</Button>
					</div>
				</DetailCard>
			</div>
			<ConfirmationPopup
				openDialog={stateConfirmationPopup}
				handleClose={() => {
					handleToggleConfirmationopup();
				}}
				handleConfirm={() => {
					sendContributionRequest();
					handleToggleConfirmationopup();
				}}
				headerTitle={'Weet u het zeker?'}
				bodyText={
					'Weet u zeker dat u naar ALLE geregisteerden een contributiefactuur wilt sturen?'
				}
			/>
		</>
	);
}
