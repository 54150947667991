import { SidebarMenuItems } from '../../../../pages/_routes';
import { SidebarItem } from '../../Molecules/SidebarItem/SidebarItem';

import styling from './_styling.module.scss';

interface IProps {}

export function Sidebar({}: IProps) {
	return (
		<div className={styling.root}>
			<div className={styling.header}>
				<img
					alt="logo"
					className={styling.headerImage}
					src={require('./../../../../assets/images/logo.png')}
				/>
				{/* <img type="file" src="'./../../../../assets/images/logo.png';" alt="logo" /> */}
			</div>
			<div className={styling.content}>
				<div className={styling.groupMenuItems}>
					{SidebarMenuItems.map((item, index) => {
						return <SidebarItem key={index} routeItem={item}></SidebarItem>;
					})}
				</div>
			</div>
			<div className={styling.footer}></div>
		</div>
	);
}
