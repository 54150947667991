import {
	TextField,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	Button,
	Checkbox,
	CircularProgress,
	Box,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { HeaderTitle } from '../../common/components/Atoms/HeaderTitle/HeaderTitle';
import { DetailCard } from '../../common/components/Organisms/DetailCard/DetailCard';
import { useUsers } from '../../common/hooks/useUsers';
import { SelectRolePopup } from '../../common/components/Atoms/Popups/SelectRolePopup';
import IUserDetails from '../../types/User/UserDetails';
import useAuth from '../../common/hooks/useAuth';
import { AccountNumberPopup } from '../../common/components/Atoms/Popups/AccountNumberPopup';
import { ChangeEmailPopup } from '../../common/components/Atoms/Popups/ChangeEmailPopup';
import { DatePicker } from '@mui/x-date-pickers';

export function UserProfile() {
	const { id } = useParams();
	const {
		getUserMe,
		getUserById,
		removeUserFromRole,
		addUserToRole,
		getUserRoles,
		updateUser,
		updateEmail,
		loading: userLoading,
		updateAccountNumber,
	} = useUsers();
	const { resendConfirmationEmail, forgotPasswordById } = useAuth();

	const [user, setUser] = useState<IUserDetails>();
	const [userRoles, setUserRoles] = useState<string[]>([]);
	const [updateButtonDisabled, setUpdateButtonDisabled] = useState<boolean>(true);

	//popup state
	const [statePopupUpdateUserRoles, setStatePopupUpdateUserRoles] = useState(false);
	const handleTogglePopupUpdateUserRoles = () => {
		setStatePopupUpdateUserRoles((prevOpen) => !prevOpen);
	};

	const [statePopupAccountNumber, setStatePopupAccountNumber] = useState(false);
	const handleToggleAccountNumberPopup = () => {
		setStatePopupAccountNumber((prevOpen) => !prevOpen);
	};

	const [statePopupChangeEmail, setStatePopupChangeEmail] = useState(false);
	const handleToggleChangeEmailPopup = () => {
		setStatePopupChangeEmail((prevOpen) => !prevOpen);
	};

	useEffect(() => {
		if (id !== undefined) {
			getUserById(id).then((data) => {
				data.dateOfBirth = new Date(data.dateOfBirth);
				setUser(data);
			});
			getUserRoles(id).then((data) => {
				setUserRoles(data);
			});
		} else {
			getUserMe().then((data) => {
				data.dateOfBirth = new Date(data.dateOfBirth);
				setUser(data);
			});
		}
	}, []);

	function updateUserLocal() {
		if (user !== undefined)
			updateUser.mutate(user as IUserDetails, {
				onSuccess: (response) => {
					response.dateOfBirth = new Date(response.dateOfBirth);
					setUser(response);
				},
			});
		setUpdateButtonDisabled(true);
	}

	function sendConfirmation() {
		if (user !== undefined) {
			resendConfirmationEmail(user.id);
		}
	}

	function forgotPassword() {
		if (user !== undefined) {
			forgotPasswordById(user.id);
		}
	}

	function updateUserRoles(roles: string[]) {
		if (roles.includes('Admin') && !userRoles.includes('Admin'))
			addUserToRole(user?.id as string, 'Admin');
		if (!roles.includes('Admin') && userRoles.includes('Admin'))
			removeUserFromRole(user?.id as string, 'Admin');

		if (roles.includes('Commission') && !userRoles.includes('Commission'))
			addUserToRole(user?.id as string, 'Commission');
		if (!roles.includes('Commission') && userRoles.includes('Commission'))
			removeUserFromRole(user?.id as string, 'Commission');

		setUserRoles([...roles]);
	}

	function handleLocationChange(e: any) {
		setUpdateButtonDisabled(false);
		let value = e.target.value;
		setUser((oldValue) => {
			const tempValue = { ...oldValue } as IUserDetails;
			tempValue.businessLocationInRegistry = value === 'business';
			tempValue.privateLocationInRegistry = value === 'private';

			return tempValue;
		});
	}

	const handleChange = (prop: keyof IUserDetails) => (e: any) => {
		setUpdateButtonDisabled(false);
		if (user !== undefined) {
			setUser((oldValue) => {
				if (oldValue !== undefined) {
					const newValue: IUserDetails = { ...oldValue };
					if (newValue !== undefined) {
						if (prop == 'dateOfBirth') {
							newValue[prop] = e;
						} else if (
							prop == 'privateLocationInRegistry' ||
							prop == 'privatePhoneInRegistry' ||
							prop == 'privateEmailInRegistry' ||
							prop == 'businessLocationInRegistry' ||
							prop == 'businessPhoneInRegistry' ||
							prop == 'businessEmailInRegistry' ||
							prop == 'websiteInRegistry' ||
							prop == 'registrationsEnabled' ||
							prop == 'accreditationsEnabled'
						) {
							newValue[prop] = e.target.checked;
						} else {
							newValue[prop] = e.target.value as never;
						}
					}

					return newValue;
				}
			});
		}
	};

	return (
		<>
			<HeaderTitle size="50px" title="Profiel" />
			{userLoading ? (
				<Box sx={{ display: 'flex' }}>
					<CircularProgress color="inherit" />
				</Box>
			) : (
				<div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
					{id !== undefined && (
						<DetailCard headerTitle="Secretariaat">
							<div id="adminSettings">
								<Button
									variant="outlined"
									size="small"
									onClick={() => {
										handleTogglePopupUpdateUserRoles();
									}}
								>
									Rollen toewijzen
								</Button>
								<Button
									variant="outlined"
									size="small"
									onClick={() => {
										sendConfirmation();
									}}
								>
									Bevestigings e-mail opnieuw sturen
								</Button>
								<Button
									variant="outlined"
									size="small"
									onClick={() => {
										forgotPassword();
									}}
								>
									Wachtwoord reset uitvoeren
								</Button>
							</div>
						</DetailCard>
					)}
					{id !== undefined && (
						<DetailCard headerTitle="Actieve registraties">
							<ul>
								{user?.basistherapeut && <li>MBT Basistherapeut</li>}
								{user?.therapeut && <li>MBT Therapeut</li>}
								{user?.sociotherapeut && <li>MBT Sociotherapeut</li>}
								{user?.vaktherapeut && <li>MBT Vaktherapeut</li>}
								{user?.systeemtherapeut && <li>MBT Systeemtherapeut</li>}
								{user?.supervisorBasistherapeut && (
									<li>MBT Supervisor in opleiding</li>
								)}
								{user?.supervisorTherapeut && <li>MBT Supervisor</li>}
								{user?.supervisorSociotherapeut && (
									<li>MBT Supervisor Sociotherapeut</li>
								)}
								{user?.supervisorVaktherapeut && (
									<li>MBT Supervisor Vaktherapeut</li>
								)}
								{user?.supervisorSysteemtherapeut && (
									<li>MBT Supervisor Systeemtherapeut</li>
								)}
							</ul>
						</DetailCard>
					)}
					{id === undefined && (
						<DetailCard headerTitle={'Account'}>
							<Button
								variant="outlined"
								size="small"
								onClick={() => {
									handleToggleChangeEmailPopup();
								}}
							>
								E-mailadres wijzigen
							</Button>
						</DetailCard>
					)}
					<DetailCard headerTitle={'Algemene gegevens'}>
						<div style={{ display: 'grid', columnGap: '5px', rowGap: '13px' }}>
							<div style={{ display: 'flex', columnGap: '5px', maxWidth: '195px' }}>
								<TextField
									style={{ width: '100%' }}
									variant="outlined"
									size="small"
									id="membershipNumber"
									label="Registratienummer"
									placeholder=""
									value={user?.membershipNumber || ''}
									disabled={true}
								/>
							</div>
							<div style={{ display: 'flex', columnGap: '5px', maxWidth: '395px' }}>
								<TextField
									style={{ width: '100%' }}
									variant="outlined"
									size="small"
									id="title"
									label="Titel"
									placeholder=""
									value={user?.title || ''}
									onChange={handleChange('title')}
								/>
								<TextField
									style={{ width: '100%' }}
									required
									variant="outlined"
									size="small"
									id="initials"
									label="Initalen"
									placeholder=""
									value={user?.initials || ''}
									onChange={handleChange('initials')}
								/>
							</div>
							<div style={{ display: 'flex', columnGap: '5px', maxWidth: '595px' }}>
								<TextField
									style={{ width: '100%' }}
									required
									variant="outlined"
									size="small"
									id="firstName"
									label="Voornaam"
									placeholder=""
									value={user?.firstName || ''}
									onChange={handleChange('firstName')}
								/>
								<TextField
									style={{ width: '100%' }}
									variant="outlined"
									size="small"
									id="middleName"
									label="Tussenvoegsel"
									placeholder=""
									value={user?.middleName || ''}
									onChange={handleChange('middleName')}
								/>
								<TextField
									style={{ width: '100%' }}
									required
									variant="outlined"
									size="small"
									id="lastName"
									label="Achternaam"
									placeholder=""
									value={user?.lastName || ''}
									onChange={handleChange('lastName')}
								/>
							</div>
							<div style={{ maxWidth: '195px' }}>
								<div style={{ width: '100%' }}>
									<DatePicker
										format="dd-MM-yyyy"
										label="Geboortedatum"
										value={user?.dateOfBirth || null}
										onChange={handleChange('dateOfBirth')}
									/>
								</div>
							</div>

							<div>
								<FormLabel id="demo-radio-buttons-group-label" required>
									Geslacht
								</FormLabel>
								<RadioGroup
									row
									value={user?.gender || ''}
									onChange={handleChange('gender')}
								>
									<FormControlLabel
										value="male"
										control={<Radio checked={user?.gender === 'male'} />}
										label="Man"
									/>
									<FormControlLabel
										value="female"
										control={<Radio checked={user?.gender === 'female'} />}
										label="Vrouw"
									/>
									<FormControlLabel
										control={
											<Radio
												checked={
													user?.gender !== 'male' &&
													user?.gender !== 'female'
												}
											/>
										}
										label="Anders"
									/>
								</RadioGroup>
								{user?.gender !== 'male' && user?.gender !== 'female' && (
									<TextField
										variant="outlined"
										size="small"
										id="gender"
										label="Anders"
										placeholder=""
										value={user?.gender}
										onChange={handleChange('gender')}
									/>
								)}
							</div>
						</div>
					</DetailCard>
					<DetailCard headerTitle={'Betalingsgegevens'}>
						<Button
							variant="outlined"
							size="small"
							onClick={() => {
								handleToggleAccountNumberPopup();
							}}
						>
							Betalingsgegevens wijzigen
						</Button>
					</DetailCard>
					<DetailCard headerTitle={'Privé gegevens'}>
						<div style={{ display: 'grid', columnGap: '5px', rowGap: '13px' }}>
							<div style={{ display: 'flex', columnGap: '5px', maxWidth: '195px' }}>
								{' '}
								<TextField
									style={{ width: '100%' }}
									variant="outlined"
									size="small"
									id="privateAddress"
									label="Adres"
									placeholder=""
									value={user?.privateAddress || ''}
									onChange={handleChange('privateAddress')}
								/>
							</div>
							<div style={{ display: 'flex', columnGap: '5px', maxWidth: '395px' }}>
								{' '}
								<TextField
									style={{ width: '100%' }}
									variant="outlined"
									size="small"
									id="privatePostalCode"
									label="Postcode"
									placeholder=""
									value={user?.privatePostalCode || ''}
									onChange={handleChange('privatePostalCode')}
								/>
								<TextField
									style={{ width: '100%' }}
									variant="outlined"
									size="small"
									id="privateCity"
									label="Stad"
									placeholder=""
									value={user?.privateCity || ''}
									onChange={handleChange('privateCity')}
								/>
							</div>
							<div style={{ display: 'flex', columnGap: '5px', maxWidth: '395px' }}>
								{' '}
								<TextField
									style={{ width: '100%' }}
									variant="outlined"
									size="small"
									id="privatePhone"
									label="Telefoonnummer"
									placeholder=""
									value={user?.privatePhone || ''}
									onChange={handleChange('privatePhone')}
								/>
								<TextField
									style={{ width: '100%' }}
									variant="outlined"
									size="small"
									id="privateMobile"
									label="Mobiel"
									placeholder=""
									value={user?.privateMobile || ''}
									onChange={handleChange('privateMobile')}
								/>
							</div>
							<div style={{ display: 'flex', columnGap: '5px', maxWidth: '395px' }}>
								{' '}
								<TextField
									style={{ width: '100%' }}
									variant="outlined"
									size="small"
									id="privateEmail"
									label="E-mail"
									placeholder=""
									value={user?.privateEmail || ''}
									onChange={handleChange('privateEmail')}
								/>
							</div>
						</div>
					</DetailCard>
					<DetailCard headerTitle={'Zakelijke gegevens'}>
						<div style={{ display: 'grid', columnGap: '5px', rowGap: '13px' }}>
							<div style={{ display: 'flex', columnGap: '5px', maxWidth: '195px' }}>
								<TextField
									required
									variant="outlined"
									size="small"
									id="businessName"
									label="Naam organisatie"
									placeholder=""
									value={user?.businessName || ''}
									onChange={handleChange('businessName')}
								/>
							</div>
							<div style={{ display: 'flex', columnGap: '5px', maxWidth: '195px' }}>
								<TextField
									style={{ width: '100%' }}
									required
									variant="outlined"
									size="small"
									id="businessAddress"
									label="Adres"
									placeholder=""
									value={user?.businessAddress || ''}
									onChange={handleChange('businessAddress')}
								/>
							</div>
							<div style={{ display: 'flex', columnGap: '5px', maxWidth: '395px' }}>
								<TextField
									style={{ width: '100%' }}
									required
									variant="outlined"
									size="small"
									id="businessPostalCode"
									label="Postcode"
									placeholder=""
									value={user?.businessPostalCode || ''}
									onChange={handleChange('businessPostalCode')}
								/>
								<TextField
									style={{ width: '100%' }}
									required
									variant="outlined"
									size="small"
									id="businessCity"
									label="Stad"
									placeholder=""
									value={user?.businessCity || ''}
									onChange={handleChange('businessCity')}
								/>
							</div>
							<div style={{ display: 'flex', columnGap: '5px', maxWidth: '195px' }}>
								<TextField
									style={{ width: '100%' }}
									required
									variant="outlined"
									size="small"
									id="businessPhone"
									label="Telefoonnummer"
									placeholder=""
									value={user?.businessPhone || ''}
									onChange={handleChange('businessPhone')}
								/>
							</div>
							<div style={{ display: 'flex', columnGap: '5px', maxWidth: '395px' }}>
								<TextField
									style={{ width: '100%' }}
									required
									variant="outlined"
									size="small"
									id="businessEmail"
									label="E-mail"
									placeholder=""
									value={user?.businessEmail || ''}
									onChange={handleChange('businessEmail')}
								/>
							</div>
							<div style={{ display: 'flex', columnGap: '5px', maxWidth: '395px' }}>
								<TextField
									style={{ width: '100%' }}
									variant="outlined"
									size="small"
									id="website"
									label="Website"
									placeholder=""
									value={user?.website || ''}
									onChange={handleChange('website')}
								/>
							</div>
						</div>
					</DetailCard>
					<DetailCard headerTitle="Vermelding in het Register MBT">
						<div>
							Met het aanmelden voor registratie geef je toestemming voor het
							vermelden van jouw registratie in het openbare Register MBT. Je hebt
							daarbij zelf de keuze met welke gegevens je terug te vinden bent. Naam,
							registratieniveau en registratienummer worden ALTIJD vermeld. Vink
							hieronder aan welke contactgegevens van jou daarnaast zichtbaar mogen
							zijn zodat je te benaderen bent voor collega’s, supervisanten en
							eventueel ook verwijzers of patiënten. Ben je geregistreerd als
							supervisor? Vink dan minimaal 1 contactmogelijkheid aan!
						</div>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<div style={{ width: '140px' }}>
								<h1
									style={{
										color: '#179e97',
										fontSize: '15px',
										lineHeight: '1.3em',
										fontWeight: 'bold',
									}}
								>
									Plaatsnaam:
								</h1>
							</div>
							<RadioGroup
								row
								value={user?.gender || ''}
								onChange={handleLocationChange}
							>
								<FormControlLabel
									value="private"
									control={<Radio checked={user?.privateLocationInRegistry} />}
									label="Privé"
								/>
								<FormControlLabel
									value="business"
									control={<Radio checked={user?.businessLocationInRegistry} />}
									label="Zakelijk"
								/>
							</RadioGroup>
						</div>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<div style={{ width: '140px' }}>
								<h1
									style={{
										color: '#179e97',
										fontSize: '15px',
										lineHeight: '1.3em',
										fontWeight: 'bold',
									}}
								>
									E-mailadres:
								</h1>
							</div>
							<div>
								<FormControlLabel
									control={
										<Checkbox
											checked={user?.privateEmailInRegistry || false}
											onChange={handleChange('privateEmailInRegistry')}
										/>
									}
									label="Privé"
								/>
							</div>
							<div>
								<FormControlLabel
									control={
										<Checkbox
											checked={user?.businessEmailInRegistry || false}
											onChange={handleChange('businessEmailInRegistry')}
										/>
									}
									label="Zakelijk"
								/>
							</div>
						</div>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<div style={{ width: '140px' }}>
								<h1
									style={{
										color: '#179e97',
										fontSize: '15px',
										lineHeight: '1.3em',
										fontWeight: 'bold',
									}}
								>
									Telefoonnummer:
								</h1>
							</div>
							<div>
								<FormControlLabel
									control={
										<Checkbox
											checked={user?.privatePhoneInRegistry || false}
											onChange={handleChange('privatePhoneInRegistry')}
										/>
									}
									label="Privé"
								/>
							</div>
							<div>
								<FormControlLabel
									control={
										<Checkbox
											checked={user?.businessPhoneInRegistry || false}
											onChange={handleChange('businessPhoneInRegistry')}
										/>
									}
									label="Zakelijk"
								/>
							</div>
						</div>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<div style={{ width: '140px' }}>
								<h1
									style={{
										color: '#179e97',
										fontSize: '15px',
										lineHeight: '1.3em',
										fontWeight: 'bold',
									}}
								>
									Website:
								</h1>
							</div>
							<div>
								<FormControlLabel
									control={
										<Checkbox
											checked={user?.websiteInRegistry || false}
											onChange={handleChange('websiteInRegistry')}
										/>
									}
									label=""
								/>
							</div>
						</div>

						<div>
							Heb je bepaalde specialisaties en/of aandachtsgebieden binnen MBT en wil
							je dat deze terug te zien zijn in het Register dan kun je deze hier
							invullen: bv MBT en Trauma, MBT-groepstherapie, MBT en LVB, MBT en
							Adolescenten, embodied mentalizing of MBT bij psychose etc
						</div>
						<TextField
							style={{ width: '100%' }}
							variant="outlined"
							size="small"
							multiline
							rows={4}
							id="extraInfo"
							label=""
							placeholder=""
							value={user?.extraInfo || ''}
							onChange={handleChange('extraInfo')}
						/>
					</DetailCard>
					<Button
						style={{ marginBottom: '20px' }}
						disabled={updateButtonDisabled}
						variant="contained"
						className="stdButton"
						onClick={() => updateUserLocal()}
					>
						Opslaan
					</Button>
				</div>
			)}

			<SelectRolePopup
				userRoles={userRoles}
				openDialog={statePopupUpdateUserRoles}
				headerTitle="Gebruikersrol bijwerken:"
				handleClose={() => {
					handleTogglePopupUpdateUserRoles();
				}}
				handleConfirm={(roles: string[]) => {
					updateUserRoles(roles);
					handleTogglePopupUpdateUserRoles();
				}}
			/>
			<AccountNumberPopup
				openDialog={statePopupAccountNumber}
				originalAccountNumber={user?.accountNumber}
				originalCheckedDirectDebit={user?.directDebitAuthorization === true}
				handleClose={() => {
					handleToggleAccountNumberPopup();
				}}
				handleConfirm={(accountNumber: string, checkedDirectDebit: boolean) => {
					updateAccountNumber(accountNumber, checkedDirectDebit).then(() => {
						setUser((oldValue) => {
							const tempValue = { ...oldValue } as IUserDetails;
							tempValue.accountNumber = accountNumber;
							tempValue.directDebitAuthorization = checkedDirectDebit;
							return tempValue;
						});
					});
					handleToggleAccountNumberPopup();
				}}
			/>
			<ChangeEmailPopup
				openDialog={statePopupChangeEmail}
				handleClose={() => {
					handleToggleChangeEmailPopup();
				}}
				handleConfirm={(email: string) => {
					updateEmail(email);
					handleToggleChangeEmailPopup();
				}}
			/>
		</>
	);
}
