import { Alert, CircularProgress } from '@mui/material';
import { ReactNode } from 'react';
import { HeaderTitle } from '../../Atoms/HeaderTitle/HeaderTitle';

import styling from './_styling.module.scss';

interface IProps {
	headerTitle: string;
	loading?: boolean;
	disabled?: boolean;
	disabledText?: string;
	children: ReactNode;
}

export function DetailCard(props: IProps) {
	const { headerTitle, children, loading = false, disabled = false, disabledText = '' } = props;

	return (
		<div className={styling.root}>
			<HeaderTitle size="25px" title={headerTitle} />

			{loading ? (
				<div className={styling.loadingWrapper}>
					<CircularProgress className={styling.loadingWrapper} color="inherit" />
				</div>
			) : (
				<>
					<>
						{disabled ? (
							<div>
								<Alert severity="warning">{disabledText}</Alert>
							</div>
						) : null}
					</>
					{children}
				</>
			)}
		</div>
	);
}
