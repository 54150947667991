export class ConstantsData {
	static readonly enumRegistrationType = [
		{ name: 'Alle', value: '' },
		{ name: 'MBT Therapeut', value: 'Therapeut' },
		{ name: 'MBT Basistherapeut', value: 'BasisTherapeut' },
		{ name: 'MBT Sociotherapeut', value: 'SocioTherapeut' },
		{ name: 'MBT Vaktherapeut', value: 'VakTherapeut' },
		{ name: 'MBT Systeemtherapeut', value: 'SysteemTherapeut' },
		{ name: 'MBT Supervisor Basistherapeut', value: 'supervisorBasistherapeut' },
		{ name: 'MBT Supervisor', value: 'supervisorTherapeut' },
		{ name: 'MBT Supervisor Sociotherapeut', value: 'supervisorSociotherapeut' },
		{ name: 'MBT Supervisor Vaktherapeut', value: 'supervisorVaktherapeut' },
		{ name: 'MBT Supervisor Systeemtherapeut', value: 'supervisorSysteemtherapeut' },
	];
}
