import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	FormGroup,
	Icon,
	Radio,
	RadioGroup,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import IAccreditation from '../../types/Accreditation/Accreditation';
import useAuth from '../../common/hooks/useAuth';
import { useAccreditations } from '../../common/hooks/useAccreditations';
import { HeaderTitle } from '../../common/components/Atoms/HeaderTitle/HeaderTitle';
import { QuestionFieldAttachment } from '../../common/components/Atoms/QuestionFields/QuestionFieldAttachment';
import { DetailCard } from '../../common/components/Organisms/DetailCard/DetailCard';
import IAccreditationEdition from '../../types/Accreditation/AccreditationEdition';
import { AddEditionPopup } from '../../common/components/Atoms/Popups/AddEditionPopup';
import { format } from 'date-fns';
import { ApproveAccreditationPopup } from '../../common/components/Atoms/Popups/ApproveAccreditationPopup';
import styling from './_stylingAccreditation.module.scss';

export function Accreditation() {
	const { id } = useParams();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user, isInRole } = useAuth();

	const {
		getAccreditationById,
		updateAccreditation,
		updateAccreditationStatus,
		deleteAccreditationById,
		addEditionToAccreditation,
		deleteEditionFromAccreditation,
		approveAccreditation,
		loadingGet: getAccreditationsIsLoading,
		loadingUpdate: updateAccreditationsIsLoading,
	} = useAccreditations();

	const [editions, setEditions] = useState<IAccreditationEdition[]>([]);
	const [accreditation, setAccreditation] = useState<IAccreditation>();
	const [accreditationIsComplete, setAccreditationIsComplete] = useState<boolean>(false);
	const [addEditionPopup, setAddEditionPopup] = useState<boolean>(false);
	const [approveAccreditationPopup, setApproveAccreditationPopup] = useState<boolean>(false);
	const [checkedApproval, setCheckedApproval] = useState<boolean>(false);

	// Only if new Accreditation is requested:
	const newAccreditation: IAccreditation = {
		accreditationStatus: 'Concept',
		requesterId: '',
		answers: [],
	};

	function checkRole(role: string): boolean {
		return isInRole(role);
	}

	useEffect(() => {
		const regex = new RegExp(
			/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
		);

		if (id !== undefined && regex.test(id)) {
			getAccreditationById(id).then((data) => {
				if (data.editions) setEditions(data.editions);
				setAccreditation(data);
			});
		} else {
			newAccreditation.accreditationStatus = 'Concept';
			newAccreditation.requesterId = user?.id as string;
			setAccreditation(newAccreditation);
		}
	}, []);

	useEffect(() => {
		CheckCompletion();
	}, [accreditation, checkedApproval]);

	function CheckCompletion() {
		let returnValue = true;

		if (!accreditation?.title) returnValue = false;
		if (!accreditation?.shortIntro) returnValue = false;
		if (!accreditation?.trainerName) returnValue = false;
		if (!accreditation?.trainerRegistrationNumber) returnValue = false;
		if (!accreditation?.activity) returnValue = false;
		if (!accreditation?.trainingTime) returnValue = false;
		if (!accreditation?.proofOfParticipation) returnValue = false;
		if (!accreditation?.trainingType) returnValue = false;
		if (!accreditation?.contactDetails) returnValue = false;
		if (!checkedApproval) returnValue = false;

		setAccreditationIsComplete(returnValue);
	}

	function addEdition(dates: Date[]) {
		const newEdition: IAccreditationEdition = {
			dates: [],
		};

		dates.forEach((date) => {
			const formattedDate = new Date(
				Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
			);
			newEdition.dates.push({ date: formattedDate });
		});

		if (!!accreditation && !!accreditation.id) {
			addEditionToAccreditation(accreditation.id, newEdition).then((response) => {
				setEditions((oldvalue) => {
					const newValues = [...oldvalue];
					newValues.push(newEdition);
					return newValues;
				});
			});
		}
	}

	function updateAccreditationLocal() {
		if (accreditation !== undefined) {
			updateAccreditation(accreditation).then((response) => {
				setAccreditation(response);
			});
		}
	}
	function saveAndSendAccreditation() {
		if (accreditation !== undefined) {
			updateAccreditation(accreditation).then((response) => {
				updateAccreditationStatus(response.id as string, 'Pending').then(() =>
					navigate('/my-accreditations')
				);
			});
		}
	}
	function sendAccreditation(status: string) {
		if (accreditation !== undefined) {
			updateAccreditationStatus(accreditation.id as string, status).then(() =>
				navigate('/my-accreditations')
			);
		}
	}
	function approve(points: number) {
		if (accreditation !== undefined) {
			approveAccreditation(accreditation.id as string, points).then(() =>
				navigate('/my-accreditations')
			);
		}
	}
	function deleteAccreditation() {
		if (accreditation !== undefined) {
			deleteAccreditationById(accreditation.id as string).then(() =>
				navigate('/my-accreditations')
			);
		}
	}

	function renderEditionsTable() {
		return (
			<Table>
				<TableHead data-test-hook="header">
					<TableRow>
						<TableCell>{t(`firstDate`)}</TableCell>
						<TableCell key="icons" align="center" size="small"></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{editions?.map((row: IAccreditationEdition, index) => (
						<TableRow key={'row' + index}>
							<TableCell>
								{`${format(new Date(row.dates[0]?.date), 'dd-MM-yyyy')} (${
									row.dates.length
								} dag(en) in totaal)`}
							</TableCell>
							<TableCell key={row.id + 'icons'} align="right">
								<Icon
									className="material-icons-outlined"
									style={{ cursor: 'pointer' }}
									onClick={() => {
										if (!!accreditation && !!accreditation.id && !!row.id) {
											deleteEditionFromAccreditation(
												accreditation.id,
												row.id
											);
										}
									}}
								>
									delete
								</Icon>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		);
	}

	function questionsDisabled() {
		return (
			accreditation?.accreditationStatus !== 'Concept' &&
			accreditation?.accreditationStatus !== 'ExtraInfoNeeded'
		);
	}

	function fieldContainsValue(field: string, value: string) {
		return field.includes(value);
	}

	function updateActivityField(value: string) {
		if (accreditation !== undefined) {
			let newActivity = accreditation.activity ?? '';
			if (newActivity.includes(value)) {
				newActivity = newActivity.replace(value, '');
			} else {
				newActivity += value;
			}

			setAccreditation((prevState) => {
				return { ...prevState, activity: newActivity } as IAccreditation;
			});
		}
	}

	function updateTrainingTypeField(value: string) {
		if (accreditation !== undefined) {
			let newTrainingType = accreditation.trainingType ?? '';
			if (newTrainingType.includes(value)) {
				newTrainingType = newTrainingType.replace(value, '');
			} else {
				newTrainingType += value;
			}

			setAccreditation((prevState) => {
				return { ...prevState, trainingType: newTrainingType } as IAccreditation;
			});
		}
	}

	const handleCheckApproval = () => {
		setCheckedApproval((originalValue) => {
			const returnValue = !originalValue;
			return returnValue;
		});
	};

	return (
		<>
			{!accreditation ? (
				<Box sx={{ display: 'flex' }}>
					<CircularProgress color="inherit" />
				</Box>
			) : (
				<>
					<HeaderTitle size="50px" title={t('headerAccreditation')} />
					<div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
						<DetailCard
							headerTitle="Edities"
							disabled={!accreditation.id}
							disabledText="Sla eerst de accreditatie op om een editie toe te voegen!"
						>
							{renderEditionsTable()}

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'flex-end',
									gridGap: '16px',
									padding: '10px 0',
								}}
							>
								<Button
									id="composition-button"
									variant="contained"
									className="stdButton"
									onClick={() => {
										setAddEditionPopup(true);
									}}
								>
									Editie toevoegen
								</Button>
							</div>
						</DetailCard>
						<DetailCard
							headerTitle={t('Scholing')}
							loading={getAccreditationsIsLoading}
						>
							<div className={styling.Root}>
								<span className={styling.Question}>01 Titel scholing</span>
								<TextField
									variant="outlined"
									size="small"
									label=""
									disabled={questionsDisabled()}
									value={accreditation.title}
									placeholder=""
									onChange={(e) => {
										setAccreditation((prevState) => {
											return {
												...prevState,
												title: e.target.value,
											} as IAccreditation;
										});
									}}
								/>
							</div>
							<div className={styling.Root}>
								<span className={styling.Question}>02 Korte inhoud</span>
								<TextField
									variant="outlined"
									size="small"
									label=""
									disabled={questionsDisabled()}
									value={accreditation.shortIntro}
									placeholder=""
									onChange={(e) => {
										setAccreditation((prevState) => {
											return {
												...prevState,
												shortIntro: e.target.value,
											} as IAccreditation;
										});
									}}
								/>
							</div>
							<div className={styling.Root}>
								<span className={styling.Question}>03 Naam docent/trainer</span>
								<TextField
									variant="outlined"
									size="small"
									label=""
									disabled={questionsDisabled()}
									value={accreditation.trainerName}
									placeholder=""
									onChange={(e) => {
										setAccreditation((prevState) => {
											return {
												...prevState,
												trainerName: e.target.value,
											} as IAccreditation;
										});
									}}
								/>
							</div>
							<div className={styling.Root}>
								<span className={styling.Question}>
									04 Registratienummer MBT-docent/trainer
								</span>
								<TextField
									variant="outlined"
									size="small"
									label=""
									disabled={questionsDisabled()}
									value={accreditation.trainerRegistrationNumber}
									placeholder=""
									onChange={(e) => {
										setAccreditation((prevState) => {
											return {
												...prevState,
												trainerRegistrationNumber: e.target.value,
											} as IAccreditation;
										});
									}}
								/>
							</div>

							<div className={styling.Root}>
								<span className={styling.Question}>05 Aard van de activiteit</span>
								<FormControlLabel
									control={
										<Checkbox
											disabled={questionsDisabled()}
											checked={fieldContainsValue(
												accreditation.activity ?? '',
												'Workshop;'
											)}
											onChange={() => {
												updateActivityField('Workshop;');
											}}
										/>
									}
									label={'Workshop'}
								/>
								<FormControlLabel
									control={
										<Checkbox
											disabled={questionsDisabled()}
											checked={fieldContainsValue(
												accreditation.activity ?? '',
												'Lecture;'
											)}
											onChange={() => {
												updateActivityField('Lecture;');
											}}
										/>
									}
									label={'Lezing/bijdrage aan een congres'}
								/>
								<FormControlLabel
									control={
										<Checkbox
											disabled={questionsDisabled()}
											checked={fieldContainsValue(
												accreditation.activity ?? '',
												'Training;'
											)}
											onChange={() => {
												updateActivityField('Training;');
											}}
										/>
									}
									label={'Training of cursus'}
								/>
								<FormControlLabel
									control={
										<Checkbox
											disabled={questionsDisabled()}
											checked={fieldContainsValue(
												accreditation.activity ?? '',
												'Other;'
											)}
											onChange={() => {
												updateActivityField('Other;');
											}}
										/>
									}
									label={'Anders'}
								/>
							</div>
							{accreditation.activity?.includes('Other;') && (
								<div className={styling.Root}>
									<span className={styling.Question}>
										Indien anders, vul dan een toelichting in.
									</span>
									<TextField
										variant="outlined"
										size="small"
										label=""
										disabled={questionsDisabled()}
										value={accreditation.activityOther}
										placeholder=""
										onChange={(e) => {
											setAccreditation((prevState) => {
												return {
													...prevState,
													activityOther: e.target.value,
												} as IAccreditation;
											});
										}}
									/>
								</div>
							)}
							<div className={styling.Root}>
								<span className={styling.Question}>
									06 Effectieve onderwijstijd (exclusief pauzes) in uren
								</span>
								<TextField
									variant="outlined"
									size="small"
									label=""
									disabled={questionsDisabled()}
									value={accreditation.trainingTime}
									placeholder=""
									onChange={(e) => {
										setAccreditation((prevState) => {
											return {
												...prevState,
												trainingTime: e.target.value,
											} as IAccreditation;
										});
									}}
								/>
							</div>
							<div className={styling.Root}>
								<span className={styling.Question}>
									07 Verplichte opdrachten (online modules, opdrachten) in uren
								</span>
								<TextField
									variant="outlined"
									size="small"
									label=""
									disabled={questionsDisabled()}
									value={accreditation.preparationTime}
									placeholder=""
									onChange={(e) => {
										setAccreditation((prevState) => {
											return {
												...prevState,
												preparationTime: e.target.value,
											} as IAccreditation;
										});
									}}
								/>
							</div>
							<div className={styling.Root}>
								<span className={styling.Question}>
									08 Verplichte literatuur in pagina’s
								</span>
								<TextField
									variant="outlined"
									size="small"
									label=""
									disabled={questionsDisabled()}
									value={accreditation.literaturePages}
									placeholder=""
									onChange={(e) => {
										setAccreditation((prevState) => {
											return {
												...prevState,
												literaturePages: e.target.value,
											} as IAccreditation;
										});
									}}
								/>
							</div>
							<div className={styling.Root}>
								<span className={styling.Question}>
									09 Deelnemers ontvangen een bewijs van deelname (verplicht voor
									accreditatie)
								</span>
								<RadioGroup
									row
									onChange={(e) => {
										setAccreditation((prevState) => {
											return {
												...prevState,
												proofOfParticipation: !!e.target.value,
											} as IAccreditation;
										});
									}}
								>
									<FormControlLabel
										value="true"
										control={
											<Radio
												disabled={questionsDisabled()}
												checked={accreditation.proofOfParticipation}
											/>
										}
										label="Ja"
									/>
									<FormControlLabel
										value="false"
										control={
											<Radio
												disabled={questionsDisabled()}
												checked={!accreditation.proofOfParticipation}
											/>
										}
										label="Nee"
									/>
								</RadioGroup>
							</div>
							<div className={styling.Root}>
								<span className={styling.Question}>
									10 Deze bijscholing is interessant voor
								</span>
								<FormControlLabel
									control={
										<Checkbox
											disabled={questionsDisabled()}
											checked={fieldContainsValue(
												accreditation.trainingType ?? '',
												'MBTBasistherapeut;'
											)}
											onChange={() => {
												updateTrainingTypeField('MBTBasistherapeut;');
											}}
										/>
									}
									label={'MBT Basistherapeuten'}
								/>
								<FormControlLabel
									control={
										<Checkbox
											disabled={questionsDisabled()}
											checked={fieldContainsValue(
												accreditation.trainingType ?? '',
												'MBTtherapeut;'
											)}
											onChange={() => {
												updateTrainingTypeField('MBTtherapeut;');
											}}
										/>
									}
									label={'MBT Therapeuten'}
								/>
								<FormControlLabel
									control={
										<Checkbox
											disabled={questionsDisabled()}
											checked={fieldContainsValue(
												accreditation.trainingType ?? '',
												'MBTSociotherapeut;'
											)}
											onChange={() => {
												updateTrainingTypeField('MBTSociotherapeut;');
											}}
										/>
									}
									label={'MBT Sociotherapeuten'}
								/>
								<FormControlLabel
									control={
										<Checkbox
											disabled={questionsDisabled()}
											checked={fieldContainsValue(
												accreditation.trainingType ?? '',
												'Vaktherapeut;'
											)}
											onChange={() => {
												updateTrainingTypeField('Vaktherapeut;');
											}}
										/>
									}
									label={'MBT Vaktherapeuten'}
								/>
								<FormControlLabel
									control={
										<Checkbox
											disabled={questionsDisabled()}
											checked={fieldContainsValue(
												accreditation.trainingType ?? '',
												'MBTSysteemtherapeut;'
											)}
											onChange={() => {
												updateTrainingTypeField('MBTSysteemtherapeut;');
											}}
										/>
									}
									label={'MBT Systeemtherapeuten'}
								/>
								<FormControlLabel
									control={
										<Checkbox
											disabled={questionsDisabled()}
											checked={fieldContainsValue(
												accreditation.trainingType ?? '',
												'Supervisor;'
											)}
											onChange={() => {
												updateTrainingTypeField('Supervisor;');
											}}
										/>
									}
									label={'MBT Supervisoren'}
								/>
							</div>
							<div className={styling.Root}>
								<span className={styling.Question}>
									11 Link naar webpagina voor meer informatie
								</span>
								<TextField
									variant="outlined"
									size="small"
									label=""
									disabled={questionsDisabled()}
									value={accreditation.infoWebPage}
									placeholder=""
									onChange={(e) => {
										setAccreditation((prevState) => {
											return {
												...prevState,
												infoWebPage: e.target.value,
											} as IAccreditation;
										});
									}}
								/>
							</div>
							<div className={styling.Root}>
								<span className={styling.Question}>
									12 Flyer (indien beschikbaar)
								</span>
								<QuestionFieldAttachment
									disabled={questionsDisabled()}
									title={''}
									value={accreditation.flyerGuid ?? ''}
									onValueChange={(e: string) => {
										setAccreditation((prevState) => {
											return {
												...prevState,
												flyerGuid: e,
											} as IAccreditation;
										});
									}}
								/>
							</div>
							<div className={styling.Root}>
								<span className={styling.Question}>
									13 Contactgegevens voor aanmelding
								</span>
								<TextField
									variant="outlined"
									size="small"
									label=""
									disabled={questionsDisabled()}
									value={accreditation.contactDetails}
									placeholder=""
									onChange={(e) => {
										setAccreditation((prevState) => {
											return {
												...prevState,
												contactDetails: e.target.value,
											} as IAccreditation;
										});
									}}
								/>
							</div>
							<div className={styling.Root}>
								<span className={styling.Question}>
									14 Link naar website voor aanmelding
								</span>
								<TextField
									variant="outlined"
									size="small"
									label=""
									disabled={questionsDisabled()}
									value={accreditation.webPage}
									placeholder=""
									onChange={(e) => {
										setAccreditation((prevState) => {
											return {
												...prevState,
												webPage: e.target.value,
											} as IAccreditation;
										});
									}}
								/>
							</div>
						</DetailCard>
						{accreditation?.accreditationStatus === 'Concept' && (
							<DetailCard headerTitle="Akkoordverklaring">
								<p>
									<span className="bold">Geldigheidsduur</span>
									<br />
									Accreditaties worden toegekend voor een periode van 3 jaar.
									Zolang de accreditatie geldig is kunnen er, ook op een later
									moment, nieuwe edities van dezelfde scholing worden toegevoegd.
									Deze edities zullen direct na invoeren verschijnen in de
									scholingsagenda. Zo is het mogelijk scholing die herhaald wordt
									in de agenda te laten opnemen zonder dat een nieuwe accreditatie
									hoeft te worden aangevraagd.
								</p>
								<p>
									<span className="bold">Kosten</span>
									<br />
									Aan het aanvragen van accreditaties voor scholing t/m 4
									accreditatiepunten zijn geen kosten verbonden. Voor aanvragen
									vanaf 5 toegekende accreditatiepunten worden wel kosten
									doorberekend. U ontvangt hiervoor na toekenning van de punten
									een factuur via het secretariaat. Zie de website voor de actuele
									tarieven.
								</p>
								<FormGroup>
									<FormControlLabel
										key={'approval'}
										value={checkedApproval}
										control={
											<Checkbox
												checked={checkedApproval}
												onChange={() => handleCheckApproval()}
											/>
										}
										label={'Ik heb al mijn gegevens naar waarheid ingevuld.'}
									/>
								</FormGroup>
							</DetailCard>
						)}
						<DetailCard headerTitle="">
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-start',
									gap: '16px',
								}}
							>
								{accreditation?.accreditationStatus === 'Concept' ? (
									<Button
										variant="outlined"
										size="small"
										onClick={() => {
											updateAccreditationLocal();
										}}
										endIcon={
											updateAccreditationsIsLoading && (
												<CircularProgress
													style={{ width: '18px', height: '18px' }}
													color="inherit"
												/>
											)
										}
									>
										Opslaan
									</Button>
								) : null}
								{accreditation?.accreditationStatus === 'Concept' ? (
									<Button
										disabled={!accreditationIsComplete}
										variant="contained"
										size="small"
										onClick={() => {
											saveAndSendAccreditation();
										}}
										endIcon={
											updateAccreditationsIsLoading ? (
												<CircularProgress
													style={{ width: '18px', height: '18px' }}
													color="inherit"
												/>
											) : (
												<Icon>send</Icon>
											)
										}
									>
										Indienen
									</Button>
								) : null}
								{accreditation?.accreditationStatus === 'Concept' ? (
									<Button
										color="error"
										variant="contained"
										size="small"
										onClick={() => {
											deleteAccreditation();
										}}
										startIcon={
											updateAccreditationsIsLoading ? (
												<CircularProgress
													style={{ width: '18px', height: '18px' }}
													color="inherit"
												/>
											) : (
												<Icon>delete</Icon>
											)
										}
									>
										Delete
									</Button>
								) : null}
								{accreditation?.accreditationStatus === 'Pending' &&
								checkRole('Commission') ? (
									<Button
										color="success"
										variant="contained"
										size="small"
										onClick={() => {
											setApproveAccreditationPopup(true);
										}}
										endIcon={
											updateAccreditationsIsLoading ? (
												<CircularProgress
													style={{ width: '18px', height: '18px' }}
													color="inherit"
												/>
											) : (
												<Icon>checkcircleoutline</Icon>
											)
										}
									>
										Goedkeuren
									</Button>
								) : null}
								{accreditation?.accreditationStatus === 'Pending' &&
								checkRole('Commission') ? (
									<Button
										color="error"
										variant="contained"
										size="small"
										onClick={() => {
											sendAccreditation('Rejected');
										}}
										endIcon={
											updateAccreditationsIsLoading ? (
												<CircularProgress
													style={{ width: '18px', height: '18px' }}
													color="inherit"
												/>
											) : (
												<Icon>cancel</Icon>
											)
										}
									>
										Afkeuren
									</Button>
								) : null}
							</div>
						</DetailCard>
					</div>
				</>
			)}
			<AddEditionPopup
				openDialog={addEditionPopup}
				expirationDate={accreditation?.expirationDate}
				handleClose={() => {
					setAddEditionPopup(false);
				}}
				handleConfirm={(dates: Date[]) => {
					addEdition(dates);
					setAddEditionPopup(false);
				}}
			/>
			<ApproveAccreditationPopup
				openDialog={approveAccreditationPopup}
				handleClose={() => {
					setApproveAccreditationPopup(false);
				}}
				handleConfirm={(points: number) => {
					approve(points);
					setApproveAccreditationPopup(false);
				}}
			/>
		</>
	);
}
