import {
	Icon,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material';
import { useEffect, useState } from 'react';
import ISupervisor from '../../../../types/User/Supervisor';
import { useUsers } from '../../../hooks/useUsers';

import styling from './_styling.module.scss';

interface IProps {
	openDialog: boolean;
	headerTitle: string;
	handleClose: Function;
	handleConfirm: Function;
}

export function SupervisorSearchPopup(props: IProps) {
	const { getSupervisors } = useUsers();
	//variables
	const { openDialog = false, headerTitle, handleClose, handleConfirm } = props;
	const [supervisors, setSupervisors] = useState<ISupervisor[]>();
	const [selectedSupervisor, setSelectedSupervisor] = useState<string>('');

	//hooks
	useEffect(() => {
		if (openDialog) {
			setSelectedSupervisor('');
		}
	}, [openDialog]);

	useEffect(() => {
		getSupervisors().then((response) => {
			setSupervisors(response);
		});
	}, [openDialog]);

	//functions
	const handleChange = (event: any) => {
		setSelectedSupervisor(event.target.value);
	};

	return (
		<Dialog open={openDialog} scroll="paper">
			<div className={styling.HeaderContainer}>
				<h1 className={styling.HeaderTitle}>{headerTitle}</h1>
			</div>
			<DialogContent dividers={true}>
				Selecteer hieronder een supervisor om deze toe te wijzen voor de video review.
				{supervisors && (
					<FormControl sx={{ m: 1, minWidth: 120, margin: 0, width: '100%' }}>
						<Select
							variant="outlined"
							value={selectedSupervisor}
							label={''}
							onChange={handleChange}
						>
							{supervisors?.map((supervisor) => (
								<MenuItem key={supervisor.id} value={supervisor.id}>
									{supervisor.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				)}
			</DialogContent>
			<DialogActions style={{ padding: '16px 24px' }}>
				<div className={styling.ActionsContainer}>
					<Button
						variant="contained"
						size="small"
						onClick={() => {
							handleConfirm(selectedSupervisor);
						}}
						endIcon={<Icon>send</Icon>}
					>
						Verstuur
					</Button>
					<Button
						color="error"
						variant="contained"
						size="small"
						onClick={() => {
							handleClose();
						}}
						endIcon={<Icon>cancel</Icon>}
					>
						Annuleren
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
}
