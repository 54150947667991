import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
	Collapse,
	Icon,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
} from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormFieldDropDown } from '../common/components/Atoms/FormFieldDropDown/FormFieldDropDown';
import InfoField from '../common/components/Molecules/InfoField/InfoField';
import Pagination from '../common/components/Molecules/Pagination/Pagination';
import { DetailCard } from '../common/components/Organisms/DetailCard/DetailCard';
import { ConstantsData } from '../common/constants/constantsData';
import { useRegister } from '../common/hooks/useRegister';
import IRegisterEntry from '../types/RegisterEntry';
import ITableColumn from '../types/TableColumn';

export default function Register() {
	const { t } = useTranslation();
	const { getRegisterBySearchQuery, loadingGet: getRegisterIsLoading } = useRegister();

	const columns: ITableColumn[] = [
		{ label: 'membershipNumber', type: 'text' },
		{ label: 'name', type: 'text' },
		{ label: 'location', type: 'text' },
	];

	const [rowOpen, setRowOpen] = useState(-1);
	const [tableSize, setTableSize] = useState<number>(0);
	const [tableRegister, setTableRegister] = useState<IRegisterEntry[]>();
	const [pageSize, setPageSize] = useState<number>(25);
	const [pageNumber, setPageNumber] = useState<number>(1);
	const [searchValueName, setSearchValueName] = useState<string>('');
	const [searchValueLocation, setSearchValueLocation] = useState<string>('');
	const [searchValueRegistrationType, setSearchValueRegistrationType] = useState<string>('');

	useEffect(() => {
		searchRegister();
	}, []);

	useEffect(() => {
		searchRegister();
	}, [searchValueLocation, searchValueName, searchValueRegistrationType, pageSize, pageNumber]);

	function handleChangeSearchValueName(event: any) {
		const newValue = event.target.value;
		setSearchValueName(newValue);
	}

	function handleChangeSearchValueLocation(event: any) {
		const newValue = event.target.value;
		setSearchValueLocation(newValue);
	}

	function handleChangeSearchValueRegistrationType(event: any) {
		const newValue = event.target.value;
		setSearchValueRegistrationType(newValue);
	}

	function searchRegister() {
		getRegisterBySearchQuery(
			pageNumber - 1,
			pageSize,
			undefined,
			searchValueLocation,
			searchValueName,
			searchValueRegistrationType
		).then((data) => {
			setTableSize(data.total);
			setTableRegister(data.entries);
		});
	}

	return (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					backgroundColor: 'white',
					padding: '10px',
					gap: '10px',
				}}
			>
				<TextField
					style={{ flex: '1' }}
					variant="outlined"
					size="small"
					label={t('Name')}
					value={searchValueName}
					placeholder=""
					onChange={handleChangeSearchValueName}
				/>
				<TextField
					style={{ flex: '1' }}
					variant="outlined"
					size="small"
					label={t('Location')}
					value={searchValueLocation}
					placeholder=""
					onChange={handleChangeSearchValueLocation}
				/>
				<FormFieldDropDown
					menuItems={ConstantsData.enumRegistrationType}
					inputValue={searchValueRegistrationType}
					setSelectedValue={handleChangeSearchValueRegistrationType}
				></FormFieldDropDown>
			</div>
			<DetailCard headerTitle="" loading={getRegisterIsLoading}>
				<Table>
					<TableHead data-test-hook="header">
						<TableRow>
							<TableCell key={'collapsicon'} style={{ width: '30px' }}></TableCell>
							{columns.map((column) => {
								return (
									<TableCell key={column.label} size="small">
										{t(`${column.label}`)}
									</TableCell>
								);
							})}
						</TableRow>
					</TableHead>
					<TableBody>
						{tableRegister?.map((row: any, index) => (
							<>
								<TableRow
									key={'row' + index}
									onClick={() => {
										setRowOpen(rowOpen === index ? -1 : index);
									}}
								>
									<TableCell key={'collapseHeader' + index} size="small">
										<IconButton aria-label="expand row" size="small">
											{rowOpen === index ? (
												<KeyboardArrowDown></KeyboardArrowDown>
											) : (
												<KeyboardArrowUp></KeyboardArrowUp>
											)}
										</IconButton>
									</TableCell>

									{columns.map((column, index) => {
										return (
											<TableCell key={'column' + index} align={column.align}>
												{column.type === 'date'
													? row[column.label]
														? format(
																new Date(row[column.label]),
																'dd-MM-yyyy'
														  )
														: ''
													: t(`${row[column.label] ?? ''}`)}
											</TableCell>
										);
									})}
								</TableRow>
								<TableRow>
									<TableCell colSpan={5} sx={{ border: 'none', padding: '0' }}>
										<Collapse
											in={rowOpen === index}
											timeout="auto"
											unmountOnExit
										>
											<div
												style={{
													display: 'row',
													flexDirection: 'column',
													gap: '1px',
												}}
											>
												<div
													style={{
														display: 'flex',
														flexDirection: 'row',
														gap: '1px',
													}}
												>
													<div style={{ flexGrow: '1' }}>
														<InfoField
															title="Naam"
															text={tableRegister[index].name}
														/>
														<InfoField
															title="Registratienummer"
															text={
																tableRegister[index]
																	.membershipNumber
															}
														/>
														<InfoField
															title="Locatie"
															text={tableRegister[index].location}
														/>
														<InfoField
															title="Telefoonnummer"
															text={
																tableRegister[index].businessPhone
															}
														/>
														<InfoField
															title="Email adres"
															text={
																tableRegister[index].businessEmail
															}
															email
														/>
														<InfoField
															title="Website"
															text={tableRegister[index].website}
															link
														/>
													</div>
													<div style={{ flexGrow: '1' }}>
														<h1
															style={{
																color: '#179e97',
																fontSize: '15px',
																lineHeight: '1.3em',
																fontWeight: 'bold',
																paddingRight: '5px',
															}}
														>
															Geregistreerd als:
														</h1>
														<ol>
															{tableRegister[index]
																.basistherapeut && (
																<li>MBT Basistherapeut</li>
															)}
															{tableRegister[index].therapeut && (
																<li>MBT Therapeut</li>
															)}
															{tableRegister[index]
																.sociotherapeut && (
																<li>MBT Sociotherapeut</li>
															)}
															{tableRegister[index].vaktherapeut && (
																<li>MBT Vaktherapeut</li>
															)}
															{tableRegister[index]
																.systeemtherapeut && (
																<li>MBT Systeemtherapeut</li>
															)}
															{tableRegister[index]
																.supervisorBasistherapeut && (
																<li>MBT Supervisor in opleiding</li>
															)}
															{tableRegister[index]
																.supervisorTherapeut && (
																<li>MBT Supervisor</li>
															)}
															{tableRegister[index]
																.supervisorSociotherapeut && (
																<li>
																	MBT Supervisor Sociotherapeut
																</li>
															)}
															{tableRegister[index]
																.supervisorVaktherapeut && (
																<li>MBT Supervisor Vaktherapeut</li>
															)}
															{tableRegister[index]
																.supervisorSysteemtherapeut && (
																<li>
																	MBT Supervisor Systeemtherapeut
																</li>
															)}
														</ol>
													</div>
												</div>
												<InfoField
													title="Extra informatie"
													text={tableRegister[index].extraInfo}
													textOrientationBelow={true}
												/>
											</div>
										</Collapse>
									</TableCell>
								</TableRow>
							</>
						))}
					</TableBody>
				</Table>
			</DetailCard>
			<Pagination
				tableSize={tableSize}
				parentCallBack={(pageSize: number, pageNumber: number) => {
					setPageSize(pageSize);
					setPageNumber(pageNumber);
				}}
			></Pagination>
		</>
	);
}
