import { Icon } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormFieldDropDown } from '../../Atoms/FormFieldDropDown/FormFieldDropDown';

import styling from './_styling.module.scss';

interface IProps {
	tableSize: number;

	//returnFunctions
	parentCallBack: Function;
}

export default function Pagination(iProperties: IProps) {
	const { tableSize, parentCallBack } = iProperties;
	const { t } = useTranslation();

	const defaultPageSize: number = 25;
	const pageSizes: any[] = [
		{ name: '10', value: 10 },
		{ name: '25', value: 25 },
		{ name: '50', value: 50 },
	];

	const [pageSize, setPageSize] = useState<number>(defaultPageSize);
	const [pageNumber, setPageNumber] = useState<number>(1);

	function handleChangePageSize(event: any) {
		const newValue = event.target.value;
		setPageSize(newValue);
		returnCallBack(newValue, pageNumber);
	}

	function handleChangeNextPage() {
		let newValue = pageNumber;
		if (newValue > tableSize / pageSize) {
			newValue = pageNumber;
		} else newValue = pageNumber + 1;
		setPageNumber(newValue);
		returnCallBack(pageSize, newValue);
	}
	function handleChangePreviousPage() {
		let newValue = pageNumber - 1;
		if (newValue === 0) {
			newValue = 1;
		}
		setPageNumber(newValue);
		returnCallBack(pageSize, newValue);
	}

	function returnCallBack(iPageSize: number, iPageNumber: number) {
		parentCallBack(iPageSize, iPageNumber);
	}

	return (
		<div className={styling.root}>
			<div className={styling.wrapper}>
				<div className={styling.pageSizeSelectorLabel}>{t('pageSizeSelectorAmount')}</div>
				<div>
					<FormFieldDropDown
						menuItems={pageSizes}
						inputValue={defaultPageSize.toString()}
						setSelectedValue={handleChangePageSize}
					></FormFieldDropDown>
				</div>
			</div>
			<div className={styling.pageSelectorWrapper}>
				<Icon
					className={`${styling.cursorIcon} material-icons-outlined`}
					onClick={handleChangePreviousPage}
				>
					navigate_before
				</Icon>
				<span>
					Pagina {pageNumber} van {Math.ceil(tableSize / pageSize)}
				</span>
				<Icon
					className={`${styling.cursorIcon} material-icons-outlined`}
					onClick={handleChangeNextPage}
				>
					navigate_next
				</Icon>
			</div>
		</div>
	);
}
