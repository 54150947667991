import useAuth from '../../common/hooks/useAuth';
import { useLocation } from 'react-router-dom';
import { Alert, CircularProgress } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

export function PasswordReset() {
	const { updatePassword, loading, error } = useAuth();
	const queryURL = useLocation().search;
	const queryEmail = new URLSearchParams(queryURL).get('email');
	const queryToken = new URLSearchParams(queryURL).get('token');
	const initialValues: any = {
		password: '',
		confirmPassword: '',
	};

	const validationSchema = Yup.object().shape({
		password: Yup.string()
			.test(
				'len',
				'Wachtwoord moet minimaal 8 tekens lang zijn',
				(val: any) => val && val.toString().length >= 8
			)
			.matches(
				/(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
				'Wachtwoord moet minimaal 1 hoofdletter, 1 kleine letter, 1 cijfer en 1 speciaal teken bevatten'
			)
			.required('This field is required!'),
		confirmPassword: Yup.string()
			.oneOf([Yup.ref('password')], 'Wachtwoorden moeten overeenkomen')
			.required('Wachtwoord moet minimaal 8 tekens lang zijn'),
	});

	const handlePasswordReset = (formValue: any) => {
		if (queryEmail && queryToken) {
			updatePassword(queryEmail, queryToken, formValue.password);
		}
	};

	return (
		<div className="loginFormBody">
			<div className="loginForm">
				<div className="loginFormContainer">
					<img
						alt="logo"
						className="loginImage"
						src={require('../../assets/images/logo.png')}
					/>
					<p className="loginFormText">Vul uw nieuwe wachtwoord in.</p>
					{error && (
						<Alert severity="warning">Inloggen mislukt, controleer gegevens.</Alert>
					)}
					<div className="card card-container">
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={handlePasswordReset}
						>
							<Form>
								{loading ? (
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
										}}
									>
										<CircularProgress
											style={{ width: '60px', height: '60px' }}
											color="inherit"
										/>
									</div>
								) : (
									<>
										<div className="form-group">
											<Field
												name="password"
												type="password"
												placeholder="Nieuw wachtwoord"
												className="loginFormField"
											/>
											<ErrorMessage
												name="password"
												component="div"
												className="alert alert-danger"
											/>
										</div>
										<div className="form-group">
											<Field
												name="confirmPassword"
												type="password"
												placeholder="Herhaal wachtwoord"
												className="loginFormField"
											/>
											<ErrorMessage
												name="confirmPassword"
												component="div"
												className="alert alert-danger"
											/>
										</div>
									</>
								)}
								<button
									type="submit"
									className="loginFormButton"
									disabled={loading}
								>
									Reset wachtwoord
								</button>
							</Form>
						</Formik>
					</div>
				</div>
			</div>
		</div>
	);
}
export default PasswordReset;
