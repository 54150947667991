import { useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, TextField } from '@mui/material';

import IQuestion from '../../../../types/Question';

import styling from './_styling.module.scss';

interface IProps {
	disabled?: boolean;
	question: IQuestion;
	value: string;
	onValueChange: Function;
}

export function QuestionFieldSelect({ question, value, disabled, onValueChange }: IProps) {
	const [checkedState, setCheckedState] = useState<boolean[]>((): boolean[] => {
		let tempArray: boolean[] = [];
		question.options.forEach((element) => {
			tempArray.push(value.split(';').indexOf(element) > -1);
		});
		return tempArray;
	});
	const [fieldValue, setFieldValue] = useState<string[]>(value == '' ? [] : value.split(';'));

	const handleChange = (position: number) => {
		const updatedCheckedState = checkedState.map((item, index) =>
			index === position ? !item : item
		);
		setCheckedState(updatedCheckedState);

		let newValue: string[] = [];
		updatedCheckedState.forEach((element, index) => {
			if (element === true) {
				newValue.push(question.options[index]);
			}
		});
		setFieldValue(newValue);
		onValueChange(newValue.join(';'));
	};

	return (
		<div className={styling.Root}>
			<span
				className={`${styling.Question} ${
					!isNaN(+question.order) ? styling.MainQuestion : ''
				}`}
			>
				{!isNaN(+question.order) && `${question.order}  `}
				{question.text}
			</span>
			<FormControl component="fieldset" variant="standard">
				<FormGroup>
					{question.options.map((option, index) => (
						<FormControlLabel
							key={option}
							value={option}
							control={
								<Checkbox
									disabled={disabled ?? false}
									checked={checkedState[index]}
									onChange={() => handleChange(index)}
								/>
							}
							label={option}
						/>
					))}
				</FormGroup>
			</FormControl>
		</div>
	);
}
