import { Alert, Button, Menu, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useAuth from '../common/hooks/useAuth';
import { useAccreditations } from '../common/hooks/useAccreditations';
import { useUsers } from '../common/hooks/useUsers';
import { useRegistrationTemplates } from '../common/hooks/useRegistrationTemplates';
import { useRegistrations } from '../common/hooks/useRegistrations';

import IAccreditation from '../types/Accreditation/Accreditation';
import IRegistration from '../types/Registration/Registration';
import IUserDetails from '../types/User/UserDetails';

import { HeaderTitle } from '../common/components/Atoms/HeaderTitle/HeaderTitle';
import { DataTable } from '../common/components/Organisms/DataTable/DataTable';
import { DetailCard } from '../common/components/Organisms/DetailCard/DetailCard';
import ILoggedOnUser from '../types/User/LoggedOnUser';
import ITableColumn from '../types/TableColumn';

export function Dashboard() {
	const { user } = useAuth();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { getUserMe, isUserProfileComplete } = useUsers();
	const { getRegistrationTemplateTypes, filterRegistrationTemplateTypes } =
		useRegistrationTemplates();
	const { getRegistrationsAll, loadingGet: getRegistrationsIsLoading } = useRegistrations();
	const { getAccreditationsAll, loadingGet: getAccreditationsIsLoading } = useAccreditations();

	const [userDetails, setUserDetails] = useState<IUserDetails>();
	const [userProfileComplete, setUserProfileComplete] = useState<boolean>(false);
	const [registrationTemplates, setRegistrationTemplates] = useState<string[]>([]);
	const [tableRegistrations, setTableRegistrations] = useState<IRegistration[]>();
	const [tableAccreditations, setTableAccreditations] = useState<IAccreditation[]>();

	//Add template menu
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const registrationsColumns: ITableColumn[] = [
		{ label: 'registrationType', type: 'text' },
		{ label: 'registrationStatus', type: 'text' },
		{ label: 'registrationDate', type: 'date' },
		{ label: 'expirationDate', type: 'date' },
	];

	const accreditationsColumns: ITableColumn[] = [
		{ label: 'title', type: 'text' },
		{ label: 'accreditationStatus', type: 'text' },
		{ label: 'accreditationPoints', type: 'text' },
		{ label: 'accreditationDate', type: 'date' },
		{ label: 'expirationDate', type: 'date' },
	];

	useEffect(() => {
		getUserMe().then((data) => {
			setUserDetails(data);
		});
		isUserProfileComplete().then((data) => {
			setUserProfileComplete(data.data);
		});
		getRegistrationsAll().then((data) => {
			setTableRegistrations(data);
		});
		getRegistrationTemplateTypes().then((data) => {
			data = filterRegistrationTemplateTypes(data, user as ILoggedOnUser);
			setRegistrationTemplates(data);
		});
		getAccreditationsAll().then((data) => {
			setTableAccreditations(data);
		});
	}, []);

	let registrationTemplateTypes: {
		menuIndex: number;
		menuLabel: string;
		menuValue: string | number;
	}[] = [];

	if (!(typeof registrationTemplates == 'undefined')) {
		registrationTemplates.forEach((value, index) => {
			registrationTemplateTypes.push({
				menuIndex: index,
				menuLabel: value,
				menuValue: value,
			});
		});
	}

	return (
		<>
			<HeaderTitle
				size="50px"
				title={`${t(`headerGreeting`)} ${userDetails?.firstName ?? ''} ${
					userDetails?.lastName ?? ''
				} `}
			/>
			<div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
				<DetailCard headerTitle={'Introductie'}>
					<div style={{ textAlign: 'justify' }}>
						<p>
							Welkom bij de registratiemodule van het Register MBT. Op deze plek kun
							je je eigen profiel beheren en je (her)registraties of accreditaties
							aanvragen.
						</p>
						<p>
							Alles start met het invullen van je eigen profiel. Je profiel bevat jouw
							persoonlijke gegevens en hier kun je ook aangeven met welke informatie
							je straks zichtbaar wordt in het Register. Bij wijzigingen in je
							contactgegevens kun je deze hier zelf doorvoeren. Dit is belangrijk
							omdat alle communicatie vanuit het Register plaatsvindt op basis van
							deze gegevens. Kloppen jouw contactgegevens niet meer dan kan het zijn
							dat je belangrijke informatie (bijvoorbeeld over herregistraties of
							facturen) mist.
						</p>
						<p>
							Nadat je profiel af is, zie je op je dashboard de mogelijkheid
							verschijnen om (her)registraties aan te vragen en accreditaties voor als
							je onderwijs geeft. Je kan een aanvraag altijd tussentijds opslaan en
							eventueel later pas, wanneer volledig, verzenden. Aangemaakte
							registraties en accreditaties verschijnen bij ‘Mijn registraties’ en
							‘Mijn accreditaties’. Daar kun je zien wat de status is van je aanvraag.
						</p>
						<p>
							Als je aanvraag is goedgekeurd door het CUR verschijnen je registraties
							automatisch in het Register en je geaccrediteerde scholing in de
							scholingskalender.
						</p>
						<p>
							Een handreiking over het gebruik van deze app en het aanvragen van
							registraties en accreditaties vind je hier:{' '}
							<a href="https://register-mbt.nl/wp-content/uploads/2023/03/Handreiking-aanvragen-registraties-en-accreditaties.pdf">
								<b>Link</b>
							</a>
						</p>
						<p>
							Alle downloads en verklaringen t.b.v. een registratie-aanvraag vind je
							hier:{' '}
							<a href="https://register-mbt.nl/documenten/">
								<b>Link</b>
							</a>
						</p>
						<p>
							<strong>Secretariaat Register MBT</strong>
							<br />
							<span>
								Wij zijn op werkdagen tussen 09.00 en 12.30 en tussen 13.30 en 16.00
								uur bereikbaar op: 085-9022868
							</span>
						</p>
						<p>
							<a title="www.register-mbt.nl" href="https://www.register-mbt.nl">
								www.register-mbt.nl
							</a>
							<br />
							<a href="mailto:info@register-mbt.nl">info@register-mbt.nl</a>
						</p>
					</div>
				</DetailCard>
				{user !== undefined && (
					<>
						{user.registrationsEnabled && (
							<DetailCard
								headerTitle={t('headerMyRegistrations')}
								loading={getRegistrationsIsLoading}
							>
								<DataTable
									navigateTo={'registration'}
									columns={registrationsColumns}
									tableData={tableRegistrations as IRegistration[]}
								></DataTable>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'flex-end',
										gridGap: '16px',
										padding: '10px 0',
									}}
								>
									{userProfileComplete ? null : (
										<Alert severity="warning">
											{t('textUserProfileIncomplete')}
										</Alert>
									)}
									<Button
										variant="contained"
										disableElevation
										disabled={!userProfileComplete}
										id="addRegistrationTemplatesMenu-button"
										aria-controls={
											open ? 'addRegistrationTemplatesMenu' : undefined
										}
										aria-haspopup="true"
										aria-expanded={open ? 'true' : undefined}
										onClick={handleClick}
									>
										{t('functionAdd')}
									</Button>
									<Menu
										id="addRegistrationTemplatesMenu"
										aria-labelledby="addRegistrationTemplatesMenu-button"
										anchorEl={anchorEl}
										open={open}
										onClose={handleClose}
										anchorOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
										transformOrigin={{
											vertical: 'bottom',
											horizontal: 'right',
										}}
									>
										{registrationTemplateTypes.map((template) => (
											<MenuItem
												key={template.menuLabel}
												onClick={() => {
													navigate(`/registration/${template.menuLabel}`);
													handleClose;
												}}
											>
												{t(template.menuLabel)}
											</MenuItem>
										))}
									</Menu>
								</div>
							</DetailCard>
						)}
						{user.accreditationsEnabled && (
							<DetailCard
								headerTitle={t('headerMyAccreditations')}
								loading={getAccreditationsIsLoading}
							>
								<DataTable
									navigateTo={'accreditation'}
									columns={accreditationsColumns}
									tableData={tableAccreditations as IAccreditation[]}
								></DataTable>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'flex-end',
										gridGap: '16px',
										padding: '10px 0',
									}}
								>
									{userProfileComplete ? null : (
										<Alert severity="warning">
											{t('textUserProfileIncompleteAccreditation')}
										</Alert>
									)}
									<Button
										variant="contained"
										disableElevation
										disabled={!userProfileComplete}
										id="addAccreditation-button"
										onClick={() => {
											navigate(`/accreditation`);
										}}
									>
										{t('functionAdd')}
									</Button>
								</div>
							</DetailCard>
						)}
					</>
				)}
			</div>
		</>
	);
}
