import { useContext, useState } from 'react';
import IRegister from '../../types/Register';
import { AxiosContext } from '../contexts/AxiosContext';

export const useRegister = () => {
	const axiosContext = useContext(AxiosContext);

	const [loadingGet, setLoadingGet] = useState(false);

	function getRegisterBySearchQuery(
		pageNumber: number = 0,
		pageSize: number = 10,
		sortBy?: string,
		locationSearch?: string,
		nameSearch?: string,
		registrationTypeSearch?: string
	) {
		var requestUrl: string = `/Search?pageNumber=${pageNumber}&pageSize=${pageSize}`;
		if (!!sortBy) {
			requestUrl += `&sortBy=${locationSearch}`;
		}
		if (!!locationSearch) {
			requestUrl += `&locationSearch=${locationSearch}`;
		}
		if (!!nameSearch) {
			requestUrl += `&nameSearch=${nameSearch}`;
		}
		if (!!registrationTypeSearch) {
			requestUrl += `&registrationTypeSearch=${registrationTypeSearch}`;
		}

		setLoadingGet(true);

		return axiosContext
			.instance!.get(requestUrl)
			.then((response) => response.data as IRegister)
			.finally(() => setLoadingGet(false));
	}

	return {
		loadingGet,
		getRegisterBySearchQuery,
	};
};
