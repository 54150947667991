import { createContext, ReactNode, useEffect, useState, useMemo } from 'react';
import IAuthResult from '../../types/User/AuthResult';
import IRegisterRequest from '../../types/User/RegisterRequest';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import ILoggedOnUser from '../../types/User/LoggedOnUser';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

interface AuthContextType {
	user?: ILoggedOnUser;
	loading: boolean;
	login: (username: string, password: string) => void;
	register: (data: IRegisterRequest) => Promise<any>;
	confirmEmail: (email: string, token: string) => void;
	resendConfirmationEmail: (email: string) => void;
	updatePassword: (email: string, token: string, password: string) => void;
	forgotPasswordByEmail: (email: string) => void;
	forgotPasswordById: (email: string) => void;
	logout: () => void;
	getUserRoles: () => any;
	isInRole: (role: string) => boolean;
	error: any;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

interface AuthProviderProps {
	children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
	const [user, setUser] = useState<ILoggedOnUser>();
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<any>();
	const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	useEffect(() => {
		if (error) setError(null);
	}, [location.pathname]);

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}authentication/current`, {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			})
			.then((result) => {
				if (result.data) setUser(result.data as ILoggedOnUser);
			})
			.catch((_error) => {})
			.finally(() => setLoadingInitial(false));
	}, []);

	function login(username: string, password: string) {
		setError('');
		setLoading(true);
		axios
			.post(`${process.env.REACT_APP_API_URL}authentication/login`, {
				username,
				password,
			})
			.then((result) => {
				localStorage.setItem('token', result.data.token);
				setUser((result.data as IAuthResult).user);
				navigate('/dashboard');
			})
			.catch((_error) => setError(_error))
			.finally(() => setLoading(false));
	}

	function logout() {
		localStorage.removeItem('token');
		setUser(undefined);
	}

	function register(data: IRegisterRequest) {
		setError('');
		setLoading(true);

		return axios
			.post(`${process.env.REACT_APP_API_URL}authentication/register`, data)
			.then(() => navigate('/login'))
			.then(() => enqueueSnackbar(t(`textSuccess_Registration`), { variant: 'info' }))
			.catch((_error) => setError(_error))
			.finally(() => setLoading(false));
	}

	function confirmEmail(email: string, token: string) {
		setError('');
		setLoading(true);

		axios
			.post(`${process.env.REACT_APP_API_URL}authentication/confirmEmail`, {
				email,
				token,
			})
			.then(() => navigate('/login'))
			.catch((_error) => setError(_error))
			.finally(() => setLoading(false));
	}

	function resendConfirmationEmail(id: string) {
		setError('');
		setLoading(true);

		return axios
			.post(`${process.env.REACT_APP_API_URL}authentication/resendConfirmationEmail`, { id })
			.catch((_error) => setError(_error))
			.finally(() => setLoading(false));
	}

	function updatePassword(email: string, token: string, password: string) {
		setError('');
		setLoading(true);

		return axios
			.post(`${process.env.REACT_APP_API_URL}authentication/resetPassword`, {
				email,
				token,
				password,
			})
			.then(() => navigate('/login'))
			.catch((_error) => setError(_error))
			.finally(() => setLoading(false));
	}

	function forgotPasswordByEmail(email: string) {
		setError('');
		setLoading(true);

		return axios
			.post(`${process.env.REACT_APP_API_URL}authentication/forgotPassword/email`, {
				email,
			})
			.then(() => navigate('/login'))
			.then(() => enqueueSnackbar(t(`textSuccess_ForgotPassword`), { variant: 'info' }))
			.catch((_error) => setError(_error))
			.finally(() => setLoading(false));
	}

	function forgotPasswordById(id: string) {
		setError('');
		setLoading(true);

		return axios
			.post(`${process.env.REACT_APP_API_URL}authentication/forgotPassword/id`, {
				id,
			})
			.catch((_error) => setError(_error))
			.finally(() => setLoading(false));
	}

	function getUserRoles() {
		let token = localStorage.getItem('token');
		if (token) {
			return jwtDecode<any>(token)[
				'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
			];
		}
		return [];
	}

	function isInRole(role: string) {
		let localUserRoles = getUserRoles();
		if (!localUserRoles || localUserRoles === undefined) {
			return false;
		} else {
			if (typeof localUserRoles === 'string') return (localUserRoles as string) == role;
			else return localUserRoles.findIndex((x: String) => x == role) > -1;
		}
	}

	const memoedValue = useMemo(
		() => ({
			user,
			loading,
			login,
			register,
			confirmEmail,
			logout,
			getUserRoles,
			resendConfirmationEmail,
			updatePassword,
			forgotPasswordByEmail,
			forgotPasswordById,
			isInRole,
			error,
		}),
		[user, loading]
	);

	return (
		<AuthContext.Provider value={memoedValue}>
			{!loadingInitial && children}
		</AuthContext.Provider>
	);
}

export default AuthContext;
