import {
	Icon,
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	FormControl,
	FormControlLabel,
	Checkbox,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IBAN from 'iban';

import styling from './_styling.module.scss';

interface IProps {
	openDialog: boolean;
	handleClose: Function;
	handleConfirm: Function;

	originalAccountNumber: string | undefined;
	originalCheckedDirectDebit: boolean;
}

export function AccountNumberPopup({
	openDialog = false,
	handleClose,
	handleConfirm,
	originalAccountNumber,
	originalCheckedDirectDebit,
}: IProps) {
	const { t } = useTranslation();
	const [accountNumber, setAccountNumber] = useState<string>('');
	const [checkedDirectDebit, setCheckedDirectDebit] = useState<boolean>(false);

	//hooks
	useEffect(() => {
		if (openDialog) {
			setAccountNumber(originalAccountNumber ?? '');
			setCheckedDirectDebit(originalCheckedDirectDebit);
		}
	}, [openDialog]);

	//functions
	const handleChange = (event: any) => {
		const newValue = event.target.value;
		setAccountNumber(newValue);
	};
	const handleChangeDirectDebit = () => {
		setCheckedDirectDebit((originalValue) => {
			const returnValue = !originalValue;
			return returnValue;
		});
	};

	function checkIban() {
		if (checkedDirectDebit) return !!accountNumber && IBAN.isValid(accountNumber);
		else return true;
	}

	return (
		<Dialog open={openDialog} scroll="paper">
			<div className={styling.HeaderContainer}>
				<h1 className={styling.HeaderTitle}>{t('AccountNumberPopup')}</h1>
			</div>
			<DialogContent dividers={true}>
				<p>
					Hieronder kunt u uw rekeningnummer wijzigen en uw incasso machtiging aan- of
					uitzetten.
				</p>
				<FormControl component="fieldset" variant="standard" style={{ width: '100%' }}>
					<FormControlLabel
						key={'directDebit'}
						value={checkedDirectDebit}
						control={
							<Checkbox
								checked={checkedDirectDebit}
								onChange={() => handleChangeDirectDebit()}
							/>
						}
						label={
							'Hierbij verleen ik het register MBT een machtiging voor automatische incasso van de jaarlijkse registratiebijdrage'
						}
					/>
				</FormControl>
				<FormControl component="fieldset" variant="standard" style={{ width: '100%' }}>
					<div className={styling.ContentContainer}>
						<TextField
							style={{ width: '100%', marginTop: '16px' }}
							className="IBAN"
							variant="outlined"
							label="IBAN"
							value={accountNumber}
							placeholder="IBAN"
							onChange={handleChange}
							error={!checkIban()}
							disabled={!checkedDirectDebit}
						/>
					</div>
				</FormControl>
			</DialogContent>
			<DialogActions style={{ padding: '16px 24px' }}>
				<div className={styling.ActionsContainer}>
					<Button
						variant="contained"
						size="small"
						onClick={() => {
							handleConfirm(accountNumber, checkedDirectDebit);
						}}
						endIcon={<Icon>send</Icon>}
					>
						Opslaan
					</Button>
					<Button
						color="error"
						variant="contained"
						size="small"
						onClick={() => {
							handleClose();
						}}
						endIcon={<Icon>cancel</Icon>}
					>
						Annuleren
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
}
