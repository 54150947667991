import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import useAuth from '../../common/hooks/useAuth';
import { Alert, CircularProgress } from '@mui/material';
import { useEffect } from 'react';

export function Login() {
	const { login, logout, loading, error } = useAuth();
	const initialValues: any = {
		username: '',
		password: '',
	};
	const validationSchema = Yup.object().shape({
		username: Yup.string().required('Dit veld is verplicht'),
		password: Yup.string().required('Dit veld is verplicht'),
	});

	const handleLogin = (formValue: { username: string; password: string }) => {
		const { username, password } = formValue;
		login(username, password);
	};

	useEffect(() => {
		logout();
	}, []);

	return (
		<div className="loginFormBody">
			<div className="loginForm" style={{ color: 'white' }}>
				<div className="loginFormContainer">
					<img
						alt="logo"
						className="loginImage"
						src={require('../../assets/images/logo.png')}
					/>
					{error && (
						<Alert severity="warning">Inloggen mislukt, controleer gegevens.</Alert>
					)}
					<div className="card card-container">
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={handleLogin}
						>
							<Form>
								{loading ? (
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
										}}
									>
										<CircularProgress
											style={{ width: '60px', height: '60px' }}
											color="inherit"
										/>
									</div>
								) : (
									<>
										<div className="form-group">
											<Field
												name="username"
												type="text"
												placeholder="E-mailadres"
												className="loginFormField"
											/>
											<ErrorMessage
												name="username"
												component="div"
												className="alert alert-danger"
											/>
										</div>
										<div className="form-group">
											<Field
												name="password"
												type="password"
												placeholder="Wachtwoord"
												className="loginFormField"
											/>
											<ErrorMessage
												name="password"
												component="div"
												className="alert alert-danger"
											/>
										</div>
									</>
								)}
								<button
									type="submit"
									className="loginFormButton"
									disabled={loading}
								>
									Login
								</button>
								<div style={{ paddingTop: '8px' }}>
									<a href="forgot-password">Wachtwoord vergeten?</a>
								</div>
							</Form>
						</Formik>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Login;
