import {
	Icon,
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	FormControl,
} from '@mui/material';
import { useEffect, useState } from 'react';

import styling from './_styling.module.scss';

interface IProps {
	openDialog: boolean;
	headerTitle: string;

	handleClose: Function;
	handleConfirm: Function;
}

export function TextFieldPopup(props: IProps) {
	//variables
	const { openDialog = false, headerTitle, handleClose, handleConfirm } = props;
	const [fieldValue, setFieldValue] = useState<string>();

	//hooks
	useEffect(() => {
		if (openDialog) {
			setFieldValue('');
		}
	}, [openDialog]);

	//functions
	const handleChange = (event: any) => {
		const newValue = event.target.value;

		setFieldValue(newValue);
	};

	return (
		<Dialog open={openDialog} scroll="paper">
			<div className={styling.HeaderContainer}>
				<h1 className={styling.HeaderTitle}>{headerTitle}</h1>
			</div>
			<DialogContent dividers={true}>
				<FormControl component="fieldset" variant="standard" style={{ width: '100%' }}>
					<div className={styling.ContentContainer}>
						<TextField
							style={{ width: '100%' }}
							variant="outlined"
							multiline
							rows={3}
							label="Commentaar"
							value={fieldValue}
							placeholder=""
							onChange={handleChange}
						/>
					</div>
				</FormControl>
			</DialogContent>
			<DialogActions style={{ padding: '16px 24px' }}>
				<div className={styling.ActionsContainer}>
					<Button
						variant="contained"
						size="small"
						onClick={() => {
							handleConfirm(fieldValue);
						}}
						endIcon={<Icon>send</Icon>}
					>
						Toevoegen
					</Button>
					<Button
						color="error"
						variant="contained"
						size="small"
						onClick={() => {
							handleClose();
						}}
						endIcon={<Icon>cancel</Icon>}
					>
						Annuleren
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
}
