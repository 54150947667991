import { useEffect, useState } from 'react';
import useAuth from '../../common/hooks/useAuth';
import { useLocation } from 'react-router-dom';
import { Alert } from '@mui/material';

export function UserConfirm() {
	const { confirmEmail, loading, error } = useAuth();
	const [warning, setWarning] = useState<boolean>(false);
	const queryURL = useLocation().search;
	const queryEmail = new URLSearchParams(queryURL).get('email');
	const queryToken = new URLSearchParams(queryURL).get('token');

	useEffect(() => {
		if (queryEmail && queryToken) {
			confirmEmail(queryEmail, queryToken);
		} else {
			setWarning(true);
		}
	}, []);

	return (
		<div className="loginFormBody">
			<div className="loginForm" style={{ color: 'white' }}>
				<div className="loginFormContainer">
					<img
						alt="logo"
						className="loginImage"
						src={require('../../assets/images/logo.png')}
					/>
					{(error || warning) && (
						<Alert severity="warning">
							De e-mailverificatie is mislukt. Neem contact op met het secretariaat om
							een nieuwe verificatielink te ontvangen.
						</Alert>
					)}
					<div className="card card-container">
						{loading && (
							<div>
								Uw e-mailadres wordt geverifieerd. U wordt automatisch doorgestuurd.
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
export default UserConfirm;
