import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderTitle } from '../../common/components/Atoms/HeaderTitle/HeaderTitle';
import { DataTable } from '../../common/components/Organisms/DataTable/DataTable';
import { DetailCard } from '../../common/components/Organisms/DetailCard/DetailCard';
import { useRegistrations } from '../../common/hooks/useRegistrations';
import { IPendingRegistrationsOverview } from '../../types/Registration/PendingRegistrationsOverview';
import ITableColumn from '../../types/TableColumn';

export function ArchivedRegistrations() {
	const { t } = useTranslation();

	const { getRegistrationsArchive, loadingGet: getRegistrationsIsLoading } = useRegistrations();
	const [registrations, setRegistrations] = useState<IPendingRegistrationsOverview[]>([]);

	useEffect(() => {
		getRegistrationsArchive().then((data) => {
			setRegistrations(data);
		});
	}, []);

	const columns: ITableColumn[] = [
		{ label: 'registrationType', type: 'text' },
		{ label: 'requesterFirstName', type: 'text' },
		{ label: 'requesterMiddleName', type: 'text' },
		{ label: 'requesterLastName', type: 'text' },
		{ label: 'registrationStatus', type: 'text' },
		{ label: 'submittedDate', type: 'date' },
		{ label: 'lastChangedDate', type: 'date' },
	];

	return (
		<>
			<HeaderTitle size="50px" title={t('headerArchivedRegistrations')} />
			<DetailCard headerTitle="" loading={getRegistrationsIsLoading}>
				<DataTable
					navigateTo={'registration'}
					columns={columns}
					tableData={registrations}
				></DataTable>
			</DetailCard>
		</>
	);
}
