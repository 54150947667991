import { useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

import IQuestion from '../../../../types/Question';

import styling from './_styling.module.scss';

interface IProps {
	disabled?: boolean;
	question: IQuestion;
	value: string;
	onValueChange: Function;
}

export function QuestionFieldRadio({ question, value, disabled, onValueChange }: IProps) {
	const [fieldValue, setFieldValue] = useState<string>(value);

	const handleChange = (event: any) => {
		const newValue = event.target.value.toString();

		setFieldValue(newValue);
		onValueChange(newValue);
	};

	return (
		<div className={styling.Root}>
			<span
				className={`${styling.Question} ${
					!isNaN(+question.order) ? styling.MainQuestion : ''
				}`}
			>
				{!isNaN(+question.order) && `${question.order}  `}
				{question.text}
			</span>
			<RadioGroup row value={fieldValue} onChange={handleChange}>
				<FormControlLabel
					value="true"
					control={<Radio disabled={disabled ?? false} checked={fieldValue === 'true'} />}
					label="Ja"
				/>
				<FormControlLabel
					value="false"
					control={
						<Radio disabled={disabled ?? false} checked={fieldValue === 'false'} />
					}
					label="Nee"
				/>
			</RadioGroup>
		</div>
	);
}
