import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
	Collapse,
	Icon,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormFieldDropDown } from '../common/components/Atoms/FormFieldDropDown/FormFieldDropDown';
import Pagination from '../common/components/Molecules/Pagination/Pagination';
import { DetailCard } from '../common/components/Organisms/DetailCard/DetailCard';
import { useCalendar } from '../common/hooks/useCalendar';
import ICalendarItem from '../types/Calendar/CalendarItem';
import ITableColumn from '../types/TableColumn';

export default function Trainings() {
	const { t } = useTranslation();
	const { getCalendar } = useCalendar();

	const columns: ITableColumn[] = [
		{ label: 'title', type: 'text' },
		{ label: 'trainerName', type: 'text' },
		{ label: 'firstDay', type: 'text' },
	];

	const [table, setTable] = useState<ICalendarItem[]>();
	const [tableSize, setTableSize] = useState<number>(0);
	const [rowOpen, setRowOpen] = useState(-1);
	const [pageSize, setPageSize] = useState<number>(25);
	const [pageNumber, setPageNumber] = useState<number>(1);

	useEffect(() => {
		loadData();
	}, []);

	useEffect(() => {
		loadData();
	}, [pageSize, pageNumber]);

	function loadData() {
		getCalendar(pageNumber - 1, pageSize).then((data) => {
			setTableSize(data.total);
			setTable(data.entries);
		});
	}

	const getLink = (text: string): string => {
		if (!text) {
			return '';
		} else if (text.startsWith('http')) {
			return text;
		} else {
			return `https://${text}`;
		}
	};

	return (
		<div style={{ backgroundColor: 'white', paddingBottom: '10px' }}>
			<DetailCard headerTitle="" loading={false}>
				<Table>
					<TableHead data-test-hook="header">
						<TableRow>
							<TableCell key={'collapsicon'} size="small"></TableCell>

							{columns.map((column) => {
								return (
									<TableCell key={column.label} size="small">
										{t(`${column.label}`)}
									</TableCell>
								);
							})}
						</TableRow>
					</TableHead>
					<TableBody>
						{table?.map((row: any, index) => (
							<>
								<TableRow
									key={'row' + index}
									onClick={() => {
										setRowOpen(rowOpen === index ? -1 : index);
									}}
								>
									<TableCell key={'collapseHeader' + index} size="small">
										<IconButton aria-label="expand row" size="small">
											{rowOpen === index ? (
												<KeyboardArrowDown></KeyboardArrowDown>
											) : (
												<KeyboardArrowUp></KeyboardArrowUp>
											)}
										</IconButton>
									</TableCell>

									{columns.map((column, index) => {
										return (
											<TableCell key={'column' + index} align={column.align}>
												{column.type === 'date'
													? row[column.label]
														? format(
																new Date(row[column.label]),
																'dd-MM-yyyy'
														  )
														: ''
													: t(`${row[column.label] ?? ''}`)}
											</TableCell>
										);
									})}
								</TableRow>
								<TableRow>
									<TableCell colSpan={5} sx={{ border: 'none', padding: '0' }}>
										<Collapse
											in={rowOpen === index}
											timeout="auto"
											unmountOnExit
										>
											<div style={{ display: 'flex', flexDirection: 'row' }}>
												<div style={{ flex: '1' }}>
													<div
														style={{
															display: 'flex',
															flexDirection: 'row',
															alignItems: 'center',
														}}
													>
														<h1
															style={{
																color: '#179e97',
																fontSize: '15px',
																lineHeight: '1.3em',
																fontWeight: 'bold',
																paddingRight: '5px',
															}}
														>
															Titel:
														</h1>
														<span>{table[index].title}</span>
													</div>
													<div
														style={{
															display: 'flex',
															flexDirection: 'row',
															alignItems: 'center',
														}}
													>
														<h1
															style={{
																color: '#179e97',
																fontSize: '15px',
																lineHeight: '1.3em',
																fontWeight: 'bold',
																paddingRight: '5px',
															}}
														>
															Naam trainer:
														</h1>
														<span>{table[index].trainerName}</span>
													</div>
													<div
														style={{
															display: 'flex',
															flexDirection: 'row',
															alignItems: 'center',
														}}
													>
														<h1
															style={{
																color: '#179e97',
																fontSize: '15px',
																lineHeight: '1.3em',
																fontWeight: 'bold',
																paddingRight: '5px',
															}}
														>
															Informatie:
														</h1>
														<span>
															<a
																target="_blank"
																href={getLink(
																	table[index].infoWebPage
																)}
															>
																{table[index].infoWebPage}
															</a>
														</span>
													</div>
													<div
														style={{
															display: 'flex',
															flexDirection: 'row',
															alignItems: 'center',
														}}
													>
														<h1
															style={{
																color: '#179e97',
																fontSize: '15px',
																lineHeight: '1.3em',
																fontWeight: 'bold',
																paddingRight: '5px',
															}}
														>
															Contactgegevens:
														</h1>
														<span>
															<a
																href={`mailto:${table[index].contactDetails}`}
															>
																{table[index].contactDetails}
															</a>
														</span>
													</div>
													<div
														style={{
															display: 'flex',
															flexDirection: 'row',
															alignItems: 'center',
														}}
													>
														<h1
															style={{
																color: '#179e97',
																fontSize: '15px',
																lineHeight: '1.3em',
																fontWeight: 'bold',
																paddingRight: '5px',
															}}
														>
															Inschrijven:
														</h1>
														<span>
															<a
																target="_blank"
																href={getLink(table[index].webPage)}
															>
																{table[index].webPage}
															</a>
														</span>
													</div>
												</div>

												<div style={{ flex: '1' }}>
													<h1
														style={{
															color: '#179e97',
															fontSize: '15px',
															lineHeight: '1.3em',
															fontWeight: 'bold',
															paddingRight: '5px',
														}}
													>
														Accredidatiepunten:
													</h1>
													<span>{table[index].accreditationPoints}</span>
													<h1
														style={{
															color: '#179e97',
															fontSize: '15px',
															lineHeight: '1.3em',
															fontWeight: 'bold',
															paddingRight: '5px',
														}}
													>
														Cursusdagen:
													</h1>
													<ol>
														{table[index].dates.map((date) => {
															return <li>{date}</li>;
														})}
													</ol>
												</div>
											</div>
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													paddingBottom: '10px',
												}}
											>
												<h1
													style={{
														color: '#179e97',
														fontSize: '15px',
														lineHeight: '1.3em',
														fontWeight: 'bold',
														paddingRight: '5px',
													}}
												>
													Korte introductie:
												</h1>
												{table[index].shortIntro}
											</div>
										</Collapse>
									</TableCell>
								</TableRow>
							</>
						))}
					</TableBody>
				</Table>
			</DetailCard>
			<Pagination
				tableSize={tableSize}
				parentCallBack={(pageSize: number, pageNumber: number) => {
					setPageSize(pageSize);
					setPageNumber(pageNumber);
				}}
			></Pagination>
		</div>
	);
}
