import {
	FormControl,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Icon,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
} from '@mui/material';
import { useState } from 'react';
import styling from './_styling.module.scss';

interface IProps {
	openDialog: boolean;
	handleClose: Function;
	handleConfirm: Function;
}

export function SelectSuperVisionPopup(props: IProps) {
	const { openDialog = false, handleClose, handleConfirm } = props;

	const [therapeutSupervisor, setTherapeutSupervisor] = useState<boolean>(false);
	const [sociotherapeutSupervisor, setSociotherapeutSupervisor] = useState<boolean>(false);
	const [vaktherapeutSupervisor, setVaktherapeutSupervisor] = useState<boolean>(false);
	const [systeemtherapeutSupervisor, setSysteemtherapeutSupervisor] = useState<boolean>(false);

	const handleChangetherapeutSupervisor = () => {
		setTherapeutSupervisor((originalValue) => {
			const returnValue = !originalValue;
			return returnValue;
		});
	};

	const handleChangeSociotherapeutSupervisor = () => {
		setSociotherapeutSupervisor((originalValue) => {
			const returnValue = !originalValue;
			return returnValue;
		});
	};
	const handleChangeVaktherapeutSupervisor = () => {
		setVaktherapeutSupervisor((originalValue) => {
			const returnValue = !originalValue;
			return returnValue;
		});
	};
	const handleChangeSysteemtherapeutSupervisor = () => {
		setSysteemtherapeutSupervisor((originalValue) => {
			const returnValue = !originalValue;
			return returnValue;
		});
	};

	return (
		<Dialog open={openDialog} scroll="paper">
			<div className={styling.HeaderContainer}>
				<h1 className={styling.HeaderTitle}>Supervisor type kiezen</h1>
			</div>
			<DialogContent dividers={true}>
				<FormControl component="fieldset" variant="standard">
					<div className={styling.ContentContainer}>
						<div>
							<p>
								Selecteer hieronder de MBT supervisor rollen die bij deze
								registratie horen:
							</p>
							<div>
								<FormGroup>
									<FormControlLabel
										key={'therapeutSupervisor'}
										value={therapeutSupervisor}
										control={
											<Checkbox
												checked={therapeutSupervisor}
												onChange={() => handleChangetherapeutSupervisor()}
											/>
										}
										label={'MBT Supervisor'}
									/>
								</FormGroup>
								<FormGroup>
									<FormControlLabel
										key={'sociotherapeutSupervisor'}
										value={sociotherapeutSupervisor}
										control={
											<Checkbox
												checked={sociotherapeutSupervisor}
												onChange={() =>
													handleChangeSociotherapeutSupervisor()
												}
											/>
										}
										label={'MBT Supervisor Sociotherapeut'}
									/>
								</FormGroup>
								<FormGroup>
									<FormControlLabel
										key={'vaktherapeutSupervisor'}
										value={vaktherapeutSupervisor}
										control={
											<Checkbox
												checked={vaktherapeutSupervisor}
												onChange={() =>
													handleChangeVaktherapeutSupervisor()
												}
											/>
										}
										label={'MBT Supervisor Vaktherapeut'}
									/>
								</FormGroup>
								<FormGroup>
									<FormControlLabel
										key={'systeemtherapeutSupervisor'}
										value={systeemtherapeutSupervisor}
										control={
											<Checkbox
												checked={systeemtherapeutSupervisor}
												onChange={() =>
													handleChangeSysteemtherapeutSupervisor()
												}
											/>
										}
										label={'MBT Supervisor Systeemtherapeut'}
									/>
								</FormGroup>
							</div>
						</div>
					</div>
				</FormControl>
			</DialogContent>
			<DialogActions style={{ padding: '16px 24px' }}>
				<div className={styling.ActionsContainer}>
					<Button
						disabled={
							!systeemtherapeutSupervisor &&
							!vaktherapeutSupervisor &&
							!sociotherapeutSupervisor &&
							!therapeutSupervisor
						}
						variant="contained"
						size="small"
						onClick={() => {
							handleConfirm(
								systeemtherapeutSupervisor,
								vaktherapeutSupervisor,
								sociotherapeutSupervisor,
								therapeutSupervisor
							);
						}}
						endIcon={<Icon>send</Icon>}
					>
						Doorgaan
					</Button>
					<Button
						color="error"
						variant="contained"
						size="small"
						onClick={() => {
							handleClose();
						}}
						endIcon={<Icon>cancel</Icon>}
					>
						Annuleren
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
}
