import { useContext, useState } from 'react';
import { AxiosContext } from '../contexts/AxiosContext';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import IRegistration from '../../types/Registration/Registration';
import { IPendingRegistrationsOverview } from '../../types/Registration/PendingRegistrationsOverview';
import IComment from '../../types/Comment';

export const useRegistrations = () => {
	const axiosContext = useContext(AxiosContext);
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [loadingGet, setLoadingGet] = useState(false);
	const [loadingUpdate, setLoadingUpdate] = useState(false);

	const getRegistrationsAll = () => {
		setLoadingGet(true);
		return axiosContext
			.instance!.get(`/registrations`)
			.then((response) => response.data as IRegistration[])
			.finally(() => setLoadingGet(false));
	};

	const getRegistrationsPending = () => {
		setLoadingGet(true);

		return axiosContext
			.instance!.get(`/registrations/pending`)
			.then((response) => response.data as IPendingRegistrationsOverview[])
			.finally(() => setLoadingGet(false));
	};

	const getRegistrationsArchive = () => {
		setLoadingGet(true);

		return axiosContext
			.instance!.get(`/registrations/archive`)
			.then((response) => response.data as IPendingRegistrationsOverview[])
			.finally(() => setLoadingGet(false));
	};

	const getRegistrationsOpen = () => {
		setLoadingGet(true);

		return axiosContext
			.instance!.get(`/registrations/open`)
			.then((response) => response.data as IPendingRegistrationsOverview[])
			.finally(() => setLoadingGet(false));
	};

	const getRegistrationById = (id: string) => {
		setLoadingGet(true);

		return axiosContext
			.instance!.get(`/registrations/${id}`)
			.then((response) => response.data as IRegistration)
			.finally(() => setLoadingGet(false));
	};

	function updateRegistration(registration: IRegistration): Promise<IRegistration> {
		setLoadingUpdate(true);

		return axiosContext
			.instance!.put(`/registrations`, registration)
			.then((response) => {
				enqueueSnackbar(t(`textSuccess_UpdateRegistration`), { variant: 'success' });
				return response.data as IRegistration;
			})
			.catch(() => {
				enqueueSnackbar(t(`textFailed_UpdateRegistration`), { variant: 'error' });
				return Promise.reject();
			})
			.finally(() => setLoadingUpdate(false));
	}

	function deleteRegistrationById(id: string) {
		setLoadingUpdate(true);

		return axiosContext
			.instance!.delete(`/registrations/${id}`)
			.then(() => {
				enqueueSnackbar(t(`textSuccess_DeleteRegistration`), { variant: 'info' });
			})
			.catch(() => {
				enqueueSnackbar(t(`textFailed_DeleteRegistration`), { variant: 'error' });
			})
			.finally(() => setLoadingUpdate(false));
	}

	//Specials
	function updateRegistrationStatus(id: string, status: string) {
		setLoadingUpdate(true);

		return axiosContext
			.instance!.put(`/registrations/${id}/status/${status}`)
			.then(() => {
				enqueueSnackbar(t(`textSuccess_UpdateStatusRegistration`), { variant: 'success' });
			})
			.catch(() => {
				enqueueSnackbar(t(`textFailure_UpdateStatusRegistration`), { variant: 'error' });
			})
			.finally(() => setLoadingUpdate(false));
	}

	function acceptSupervisorRegistration(
		id: string,
		systeemtherapeutSupervisor: boolean,
		vaktherapeutSupervisor: boolean,
		sociotherapeutSupervisor: boolean,
		therapeutSupervisor: boolean
	) {
		setLoadingUpdate(true);

		return axiosContext
			.instance!.put(`/registrations/${id}/accept/supervisor`, {
				systeemtherapeutSupervisor,
				vaktherapeutSupervisor,
				sociotherapeutSupervisor,
				therapeutSupervisor,
			})
			.then(() => {
				enqueueSnackbar(t(`textSuccess_UpdateStatusRegistration`), { variant: 'success' });
			})
			.catch(() => {
				enqueueSnackbar(t(`textFailure_UpdateStatusRegistration`), { variant: 'error' });
			})
			.finally(() => setLoadingUpdate(false));
	}

	function submitRegistration(id: string, accountNumber: string) {
		setLoadingUpdate(true);

		return axiosContext
			.instance!.put(`/registrations/${id}/submit`, { accountNumber: accountNumber })
			.then((response) => {
				enqueueSnackbar(t(`textSuccess_SubmitRegistration`), { variant: 'success' });
				return response.data;
			})
			.catch(() => {
				enqueueSnackbar(t(`textFailed_SubmitRegistration`), { variant: 'error' });
			})
			.finally(() => setLoadingUpdate(false));
	}

	function setSupervisorState(id: string, supervisorId: string) {
		setLoadingUpdate(true);

		return axiosContext
			.instance!.put(`/registrations/${id}/supervisor`, { supervisorId: supervisorId })
			.then((response) => {
				enqueueSnackbar(t(`textSuccess_UpdateStatusRegistration`), { variant: 'success' });
				return response.data;
			})
			.catch(() => {
				enqueueSnackbar(t(`textFailure_UpdateStatusRegistration`), { variant: 'error' });
			})
			.finally(() => setLoadingUpdate(false));
	}

	const UploadComment = (id: string, comment: IComment) => {
		return axiosContext
			.instance!.put(`/registrations/${id}/comment`, comment)
			.then((response) => response.data);
	};

	return {
		getRegistrationsAll,
		getRegistrationsPending,
		getRegistrationsArchive,
		getRegistrationsOpen,
		getRegistrationById,
		updateRegistration,
		updateRegistrationStatus,
		deleteRegistrationById,
		UploadComment,
		submitRegistration,
		acceptSupervisorRegistration,
		setSupervisorState,
		loadingGet,
		loadingUpdate,
	};
};
