import {
	FormControl,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Icon,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styling from './_styling.module.scss';

interface IProps {
	userRoles: string[];
	openDialog: boolean;
	headerTitle: string;
	handleClose: Function;
	handleConfirm: Function;
}

export function SelectRolePopup({
	userRoles,
	openDialog = false,
	headerTitle,
	handleClose,
	handleConfirm,
}: IProps) {
	const { t } = useTranslation();
	const [userIsAdmin, setUserIsAdmin] = useState(false);
	const [userIsCommission, setUserIsCommission] = useState(false);

	function handleUpdateUserRole() {
		let roles = [];
		if (userIsAdmin) roles.push('Admin');
		if (userIsCommission) roles.push('Commission');
		handleConfirm(roles);
	}

	useEffect(() => {
		if (userRoles.includes('Admin')) setUserIsAdmin(true);
		if (userRoles.includes('Commission')) setUserIsCommission(true);
	}, [userRoles]);

	return (
		<div>
			<Dialog open={openDialog} scroll="paper">
				<div className={styling.HeaderContainer}>
					<h1 className={styling.HeaderTitle}>{headerTitle}</h1>
				</div>
				<DialogContent dividers={true}>
					<FormControl component="fieldset" variant="standard">
						<div className={styling.ContentContainer}>
							<div>
								<FormGroup>
									<FormControlLabel
										key={'Admin'}
										value={userIsAdmin}
										control={
											<Checkbox
												checked={userIsAdmin}
												onChange={() => setUserIsAdmin(!userIsAdmin)}
											/>
										}
										label={t('Admin')}
									/>
									<FormControlLabel
										key={'Commission'}
										value={userIsCommission}
										control={
											<Checkbox
												checked={userIsCommission}
												onChange={() =>
													setUserIsCommission(!userIsCommission)
												}
											/>
										}
										label={t('Commission')}
									/>
								</FormGroup>
							</div>
						</div>
					</FormControl>
				</DialogContent>
				<DialogActions style={{ padding: '16px 24px' }}>
					<div className={styling.ActionsContainer}>
						<Button
							variant="contained"
							size="small"
							onClick={() => {
								handleUpdateUserRole();
							}}
							endIcon={<Icon>send</Icon>}
						>
							Akkoord
						</Button>
						<Button
							color="error"
							variant="contained"
							size="small"
							onClick={() => {
								handleClose();
							}}
							endIcon={<Icon>cancel</Icon>}
						>
							Annuleren
						</Button>
					</div>
				</DialogActions>
			</Dialog>{' '}
		</div>
	);
}
