import { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import IRegisterRequest from '../../types/User/RegisterRequest';
import useAuth from '../../common/hooks/useAuth';
import { Alert, CircularProgress, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function UserRegister() {
	const { register, error, loading } = useAuth();
	const { t } = useTranslation();
	const initialValues: IRegisterRequest = {
		email: '',
		password: '',
		confirmPassword: '',
		registrationsEnabled: true,
		accreditationsEnabled: false,
	};
	const [registrationsEnabled, setRegistrationsEnabled] = useState<boolean>(
		initialValues.registrationsEnabled
	);
	const [accreditationsEnabled, setAccreditationsEnabled] = useState<boolean>(
		initialValues.accreditationsEnabled
	);

	const validationSchema = Yup.object().shape({
		email: Yup.string()
			.required('Dit veld is verplicht')
			.email('Dit is geen geldig e-mailadres'),
		password: Yup.string()
			.test(
				'len',
				'Wachtwoord moet minimaal 8 tekens lang zijn',
				(val: any) => val && val.toString().length >= 8
			)
			.matches(
				/(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
				'Wachtwoord moet minimaal 1 hoofdletter, 1 kleine letter, 1 cijfer en 1 speciaal teken bevatten'
			)
			.required('This field is required!'),
		confirmPassword: Yup.string()
			.oneOf([Yup.ref('password')], 'Wachtwoorden moeten overeenkomen')
			.required('Wachtwoord moet minimaal 8 tekens lang zijn'),
	});

	const handleRegister = (formValue: IRegisterRequest) => {
		formValue.accreditationsEnabled = accreditationsEnabled;
		formValue.registrationsEnabled = registrationsEnabled;
		register(formValue);
	};

	const [tabIndex, setTabIndex] = useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		if (newValue === 0) {
			setRegistrationsEnabled(true);
			setAccreditationsEnabled(false);
		}
		if (newValue === 1) {
			setRegistrationsEnabled(false);
			setAccreditationsEnabled(true);
		}
		setTabIndex(newValue);
	};

	return (
		<div className="loginFormBody">
			<div className="loginForm">
				<Tabs
					value={tabIndex}
					onChange={handleChange}
					indicatorColor="secondary"
					textColor="inherit"
					variant="fullWidth"
					aria-label="full width tabs example"
					style={{ color: 'white' }}
				>
					<Tab label="Register" />
					<Tab label="Accreditatie" />
				</Tabs>
				<div className="loginFormContainer">
					<img
						alt="logo"
						className="loginImage"
						src={require('../../assets/images/logo.png')}
					/>
					<p className="loginFormText">
						Selecteer hierboven of u een account wilt aanmaken voor het register of om
						een accreditatieaanvraag te kunnen doen. Vul daarna een e-mailadres en een
						wachtwoord in om een account aan te maken.
					</p>
					{error && (
						<Alert severity="warning">
							Registeren mislukt, controleer de gegevens of neem contact op met het
							secretariaat.
						</Alert>
					)}
					<div className="card card-container">
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={handleRegister}
						>
							<Form>
								{loading ? (
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
										}}
									>
										<CircularProgress
											style={{ width: '60px', height: '60px' }}
											color="inherit"
										/>
									</div>
								) : (
									<>
										<div className="form-group">
											<Field
												name="email"
												type="email"
												placeholder="E-mail"
												className="loginFormField"
											/>
											<ErrorMessage
												name="email"
												component="div"
												className="alert alert-danger"
											/>
										</div>
										<div className="form-group">
											<Field
												name="password"
												type="password"
												placeholder="Wachtwoord"
												className="loginFormField"
											/>
											<ErrorMessage
												name="password"
												component="div"
												className="alert alert-danger"
											/>
										</div>
										<div className="form-group">
											<Field
												name="confirmPassword"
												type="password"
												placeholder="Herhaal wachtwoord"
												className="loginFormField"
											/>
											<ErrorMessage
												name="confirmPassword"
												component="div"
												className="alert alert-danger"
											/>
										</div>
									</>
								)}
								<button type="submit" className="loginFormButton">
									Registreer
								</button>
							</Form>
						</Formik>
					</div>
				</div>
			</div>
		</div>
	);
}
export default UserRegister;
