import {
	Icon,
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	FormControl,
} from '@mui/material';
import { useEffect, useState } from 'react';

import styling from './_styling.module.scss';

interface IProps {
	openDialog: boolean;
	handleClose: Function;
	handleConfirm: Function;
}

export function ApproveAccreditationPopup({
	openDialog = false,
	handleClose,
	handleConfirm,
}: IProps) {
	const [points, setPoints] = useState<string | null>(null);

	//hooks
	useEffect(() => {
		if (openDialog) {
			setPoints(null);
		}
	}, [openDialog]);

	return (
		<Dialog open={openDialog} scroll="paper">
			<div className={styling.HeaderContainer}>
				<h1 className={styling.HeaderTitle}>Goedkeuren accreditatie</h1>
			</div>
			<DialogContent dividers={true}>
				<p>Vul hieronder het aantal punten in voor deze accreditatie:</p>
				<FormControl
					component="fieldset"
					variant="standard"
					style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '10px' }}
				>
					<TextField
						style={{ width: '100%' }}
						variant="outlined"
						label=""
						value={points}
						type="number"
						placeholder=""
						onChange={(event) => setPoints(event.target.value)}
					/>
				</FormControl>
			</DialogContent>
			<DialogActions style={{ padding: '16px 24px' }}>
				<div className={styling.ActionsContainer}>
					<Button
						disabled={!(!!points && +points > 0)}
						variant="contained"
						size="small"
						onClick={() => {
							if (!!points) handleConfirm(+points);
						}}
						endIcon={<Icon>send</Icon>}
					>
						Opslaan
					</Button>
					<Button
						color="error"
						variant="contained"
						size="small"
						onClick={() => {
							handleClose();
						}}
						endIcon={<Icon>cancel</Icon>}
					>
						Annuleren
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
}
